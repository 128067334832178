import * as PhosphorIcons from '@phosphor-icons/react';
import { AppState } from '@src/app/rootReducer';
import { Theme } from '@src/styles/themes/themeInterface';
import { hexToRGBA, removeTrailingColon } from '@src/utils/common';
import { searchEmojis } from '@src/utils/emojiSearch';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { LocalIcons } from './icons';

type ColorVariant =
  | 'content-primary'
  | 'content-secondary'
  | 'content-static'
  | 'content-success'
  | 'content-error'
  | 'content-tertiary'
  | 'content-accentIcon'
  | 'content-accentText'
  | 'currentColor';

// Define the props interface for the FltIcon component
interface IconProps {
  name: string; // Name of the icon
  weight?: 'thin' | 'light' | 'regular' | 'bold' | 'fill' | 'duotone'; // Weight of the icon (default: 'regular')
  size?: number; // Size of the icon (default: 24)
  color?: string;
  iconColor?: ColorVariant; // Color of the icon (default: 'currentColor')
  bg?: boolean;
  source?: 'phosphor-icons' | 'local' | 'emoji'; // Source of the icon (default: 'phosphor-icons')
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const IconWrapper = styled.div<{ bg: string; size: number }>`
  display: flex;
  width: ${({ size }) => size + 4}px;
  height: ${({ size }) => size + 4}px;
  padding: var(--Spacing-1, 2px);
  justify-content: center;
  align-items: center;

  border-radius: var(--Spacing-1, 2px);
  background: ${({ bg }) => bg};
`;
const FltIcon: React.FC<IconProps> = memo(
  ({
    name = 'AirPlane',
    weight = 'regular',
    size = 24,
    onClick,
    color, // Pass if color is directly set
    iconColor = 'currentColor', // Default color
    source = 'phosphor-icons',
    bg = false,
  }) => {
    const theme = useSelector((data: AppState) => data.theme.theme);
    const [Icon, setIcon] = useState<React.FC<any> | null>(null);
    const [emoji, setEmoji] = useState<string | null>(null);

    useEffect(() => {
      const fetchEmoji = async () => {
        if (name && name.endsWith(':')) {
          const emojiResult = await searchEmojis(removeTrailingColon(name));
          setEmoji(emojiResult);
        } else {
          setEmoji(null);
        }
      };

      fetchEmoji();
    }, [name]);

    // Helper function to get text color from theme based on the textColor prop
    const getColor = (iconColor: ColorVariant, theme: Theme) => {
      if (iconColor === 'content-accentIcon') {
        return theme.content.accentIcon || iconColor;
      }

      const colorKey = iconColor.split('-')[1]; // Extract color part after 'content-'
      return theme.content[colorKey as keyof typeof theme.content] || iconColor;
    };

    useEffect(() => {
      if (source === 'phosphor-icons') {
        const IconComponent = PhosphorIcons[name as keyof typeof PhosphorIcons] as React.FC<any>;

        if (IconComponent && typeof IconComponent === 'object') {
          setIcon(() => IconComponent);
        }
      }
    }, [name, source]);

    // Handle local icons
    if (source === 'local') {
      const LocalIcon = LocalIcons[name];
      if (LocalIcon && typeof LocalIcon === 'function') {
        return <LocalIcon size={size} color={color} onClick={onClick} />;
      } else {
        console.error(`Local icon ${name} is not a valid React component`);
        return <span>Local icon not found</span>;
      }
    }

    // Render emoji if it exists
    if (emoji) {
      return <span style={{ fontSize: size }}>{emoji}</span>;
    }

    // Return a default icon or placeholder if the icon hasn't been loaded yet
    if (!Icon) {
      return <PhosphorIcons.ImageBroken size={size} color={theme.content.primary} />;
    }

    // Render with background if bg is true
    if (bg && color) {
      return (
        <IconWrapper bg={hexToRGBA(color)} size={size}>
          <Icon weight={weight} size={size} color={color} onClick={onClick} />
        </IconWrapper>
      );
    }

    // If color is passed, it should take priority over iconColor
    const finalColor = color || getColor(iconColor, theme);

    // Render the icon
    return <Icon weight={weight} size={size} color={finalColor} onClick={onClick} />;
  }
);

export default FltIcon;
