import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Groups } from '@src/dbSyncManager/tables/groups.entity';
import { ProcessedEventMessage } from '@src/dbSyncManager/tables/processedEventMessage.entity';
import { PublishedEvent } from '@src/dbSyncManager/tables/publishedEvents.entity';

interface PublishedEventsState {
  mapData: Map<number, any>;
  arrayData: any[];
}

const initialState: PublishedEventsState = {
  mapData: new Map(),
  arrayData: [],
};

const publishedEventsSlice = createSlice({
  name: 'publishedEvents',
  initialState,
  reducers: {
    setPublishedEvents(state, action: PayloadAction<PublishedEvent[]>) {
      state.arrayData = action.payload;
      state.mapData = new Map(action.payload.map((item) => [item.id, item]));
    },
  },
});

export const { setPublishedEvents } = publishedEventsSlice.actions;
export default publishedEventsSlice.reducer;
