import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Document } from '@src/dbSyncManager/tables/documents.entity';

interface DocumentState {
  mapData: Map<number | null, any>;
  arrayData: any[];
}

const initialState: DocumentState = {
  mapData: new Map(),
  arrayData: [],
};

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setDocuments(state, action: PayloadAction<Document[]>) {
      state.arrayData = action.payload;
      state.mapData = new Map(action.payload.map((item) => [item.id, item]));
    },
  },
});

export const { setDocuments } = documentsSlice.actions;
export default documentsSlice.reducer;
