import { AppState } from '@src/app/rootReducer';
import FltText, { TextColorVariant, TextVariant } from '@src/components/base/fltText/fltText';
import { GroupNotificationOperation, NotificationEventType } from '@src/constants/enum';
import { ProcessedEventMessage } from '@src/dbSyncManager/tables/processedEventMessage.entity';
import { Theme } from '@src/styles/themes/themeInterface';
import React from 'react';
import { useSelector } from 'react-redux';
import NotificationDisplay from './notificationDisplay';

type NotificationMessageProps = {
  processedEventMessageData: ProcessedEventMessage;
  textVariant?: TextVariant;
  textColor?: TextColorVariant;
  initiatorNameNone?: boolean;
  iconNone?: boolean;
};

export const GroupsMessageGenerator: React.FC<NotificationMessageProps> = ({
  processedEventMessageData,
  textVariant = 'label-small',
  textColor = 'content-tertiary',
  initiatorNameNone = false,
  iconNone = false,
}) => {
  const publishedEventsMap = useSelector((data: AppState) => data.publishedEvents.mapData);
  const notificationData = publishedEventsMap.get(processedEventMessageData.publishedEventId);
  const groupsMap = useSelector((state: AppState) => state.groups.mapData);
  const usersMap = useSelector((state: AppState) => state.users.mapData);
  const currentUserId = useSelector((state: AppState) => state.auth.userId);
  let processedData = {
    initiatorName: '',
    targetUserName: '',
    teamName: '',
    teamIcon: '',
    teamColor: '',
    targetTeamName: '',
    targetTeamIcon: '',
    targetTeamColor: '',
  };

  const initiatorUser = usersMap.get(notificationData.createdById);
  processedData.initiatorName =
    notificationData.createdById == currentUserId ? 'you' : initiatorUser.firstName;
  if (
    notificationData.eventName === NotificationEventType.TEAM_DEPENDENT_ADDED ||
    notificationData.eventName === NotificationEventType.TEAM_DEPENDENT_REMOVED
  ) {
    const targetedTeam = groupsMap.get(
      (notificationData.templateValues as { targetTeamId: number }).targetTeamId
    );
    processedData.targetTeamName = targetedTeam?.name;
    processedData.targetTeamIcon = targetedTeam?.icon;
    processedData.targetTeamColor = targetedTeam?.color;

    const initiatorTeam = groupsMap.get(notificationData.forId);
    processedData.teamName = initiatorTeam?.name;
    processedData.teamIcon = initiatorTeam?.icon;
    processedData.teamColor = initiatorTeam?.color;
  }
  if (
    notificationData.eventName === NotificationEventType.TEAM_MEMBER_ADDED ||
    notificationData.eventName === NotificationEventType.TEAM_MEMBER_REMOVED ||
    notificationData.eventName === NotificationEventType.TEAM_MEMBER_ROLE_UPDATED ||
    notificationData.eventName === NotificationEventType.INITIATIVE_MEMBER_ADDED ||
    notificationData.eventName === NotificationEventType.INITIATIVE_MEMBER_REMOVED ||
    notificationData.eventName === NotificationEventType.INITIATIVE_MEMBER_ROLE_UPDATED
  ) {
    const targetUser = usersMap.get(
      (notificationData.templateValues as { targetUserId: number }).targetUserId
    );
    processedData.targetUserName = targetUser?.firstName;

    const initiatorTeam = groupsMap.get(notificationData.forId);
    processedData.teamName = initiatorTeam?.name;
    processedData.teamIcon = initiatorTeam?.icon || 'MagicWand';
    processedData.teamColor = initiatorTeam?.color;
  }

  if (
    notificationData.eventName === NotificationEventType.TEAM_SETTING_UPDATED ||
    notificationData.eventName === NotificationEventType.INITIATIVE_SETTING_UPDATED
  ) {
    const initiatorTeam = groupsMap.get(notificationData.forId);
    processedData.teamName = initiatorTeam?.name;
    processedData.teamIcon = initiatorTeam?.icon || 'MagicWand';
    processedData.teamColor = initiatorTeam?.color;
  }
  if (
    notificationData.eventName == NotificationEventType.INITIATIVE_WORKOBJECT_ADDED ||
    notificationData.eventName == NotificationEventType.INITIATIVE_WORKOBJECT_UPDATED
  ) {
    const initiatorTeam = groupsMap.get(notificationData.forId);
    processedData.teamName = initiatorTeam?.name;
    processedData.teamIcon = initiatorTeam?.icon || 'MagicWand';
    processedData.teamColor = initiatorTeam?.color;
  }

  let messageParts: any[] = [];
  const createMessagePart = (type: string, data: any, iconNone: boolean) => {
    const {
      initiatorName,
      targetUserName,
      teamIcon,
      teamColor,
      teamName,
      targetTeamIcon,
      targetTeamColor,
      targetTeamName,
    } = data;

    switch (type) {
      case NotificationEventType.TEAM_MEMBER_ADDED:
      case NotificationEventType.INITIATIVE_MEMBER_ADDED:
        return [
          { primaryName: initiatorName },
          { primaryConnector: 'added' },
          { secondaryName: targetUserName },
          { secondaryConnector: 'as a member to' },
          !iconNone && { secondaryIcon: { icon: teamIcon || 'MagicWand', color: teamColor } },
          { tertiaryName: teamName },
        ];

      case NotificationEventType.TEAM_MEMBER_REMOVED:
      case NotificationEventType.INITIATIVE_MEMBER_REMOVED:
        return [
          { primaryName: initiatorName },
          { primaryConnector: 'removed' },
          { secondaryName: targetUserName },
          { secondaryConnector: 'from' },
          !iconNone && { secondaryIcon: { icon: teamIcon || 'MagicWand', color: teamColor } },
          { tertiaryName: teamName },
        ];

      case NotificationEventType.TEAM_SETTING_UPDATED:
      case NotificationEventType.INITIATIVE_SETTING_UPDATED:
        return [
          { primaryName: initiatorName },
          { primaryConnector: 'updated' },
          !iconNone && { primaryIcon: { icon: teamIcon || 'MagicWand', color: teamColor } },
          { secondaryName: teamName },
          { tertiaryName: 'settings' },
        ];

      case NotificationEventType.INITIATIVE_WORKOBJECT_ADDED:
        return [
          { primaryName: initiatorName },
          { primaryConnector: 'workobject added for' },
          !iconNone && { primaryIcon: { icon: teamIcon || 'MagicWand', color: teamColor } },
          { secondaryName: teamName },
        ];
      case NotificationEventType.INITIATIVE_WORKOBJECT_REMOVED:
        return [
          { primaryName: initiatorName },
          { primaryConnector: 'workobject removed from' },
          !iconNone && { primaryIcon: { icon: teamIcon || 'MagicWand', color: teamColor } },
          { secondaryName: teamName },
        ];
      case NotificationEventType.INITIATIVE_WORKOBJECT_UPDATED:
        return [
          { primaryName: initiatorName },
          { primaryConnector: 'workobject updated for' },
          !iconNone && { primaryIcon: { icon: teamIcon || 'MagicWand', color: teamColor } },
          { secondaryName: teamName },
        ];

      case NotificationEventType.TEAM_DEPENDENT_ADDED:
        const actionAdd =
          notificationData.templateValues.action === GroupNotificationOperation.GROUP_CHILD_ADD
            ? 'child'
            : 'parent';
        return [
          { primaryName: initiatorName },
          { primaryConnector: 'added' },
          !iconNone && { primaryIcon: { icon: teamIcon, color: teamColor } },
          { secondaryName: teamName },
          { secondaryConnector: 'as a' },
          { tertiaryName: actionAdd },
          { tertiaryConnector: 'to' },
          !iconNone && { tertiaryIcon: { icon: targetTeamIcon, color: targetTeamColor } },
          { targetName: targetTeamName },
        ];

      case NotificationEventType.TEAM_DEPENDENT_REMOVED:
        const actionRemove =
          notificationData.templateValues.action === GroupNotificationOperation.GROUP_CHILD_DELETE
            ? 'child'
            : 'parent';
        return [
          { primaryName: initiatorName },
          { primaryConnector: 'removed' },
          { secondaryName: teamName },
          { secondaryConnector: 'as a' },
          { tertiaryName: actionRemove },
          { tertiaryConnector: 'of' },
          !iconNone && { tertiaryIcon: { icon: targetTeamIcon, color: targetTeamColor } },
          { targetName: targetTeamName },
        ];

      case NotificationEventType.TEAM_MEMBER_ROLE_UPDATED:
      case NotificationEventType.INITIATIVE_MEMBER_ROLE_UPDATED:
        return [
          { primaryName: initiatorName },
          { primaryConnector: 'updated' },
          { secondaryName: targetUserName },
          { secondaryConnector: 'role to' },
          !iconNone && { secondaryIcon: { icon: 'Users' } },
          { tertiaryName: notificationData.templateValues.role },
        ];

      default:
        return [<span key="default">No message available</span>];
    }
  };

  messageParts = createMessagePart(notificationData.eventName, processedData, iconNone);
  return (
    <FltText
      variant={textVariant}
      textColor={textColor}
      isEllipsis={true}
      styles={{ display: 'flex', alignItems: 'center', gap: '3px' }}
    >
      <NotificationDisplay
        messageParts={messageParts}
        textVariant={textVariant}
        textColor={textColor}
      />
    </FltText>
  );
};
