import Avatar from '@mui/material/Avatar';
import { AppState } from '@src/app/rootReducer';
import FltText from '@src/components/base/fltText/fltText';
import { FltTooltip } from '@src/components/base/fltTooltip/fltTooltip';
import { getDisplayName } from '@src/utils/common';
import React from 'react';
import { useSelector } from 'react-redux';
import { CustomAvatarProps } from './avatarInterface';
import { ToolTipContainerNew } from '../stackedAvatars/stackedAvatarsStyle';
import { Theme } from '@src/styles/themes/themeInterface';

/**
 * CustomAvatar is a functional React component that renders a customized avatar.
 * If `tooltip` is true, it wraps the avatar in a `FltTooltip` that shows additional details.
 */

const CustomAvatar: React.FC<CustomAvatarProps> = ({
  bgcolor,
  avatarColor,
  fontSize,
  width,
  height,
  marginLeft,
  verticalAlign,
  preferredName,
  firstName,
  lastName,
  avatarInitials,
  style,
  tooltip = false,
  id,
  shape = 'default',
}) => {
  const displayName = getDisplayName(
    useSelector((state: AppState) => state?.displayName?.displayName),
    id
  );
  const theme:Theme = useSelector((state: AppState) => state.theme.theme);

  const avatar = (
    <Avatar
      sx={{
        backgroundColor: bgcolor || avatarColor || '',
        color: theme.content.accentText,
        fontSize: fontSize || '9px',
        width: width || '20px',
        height: height || '20px',
        marginLeft: marginLeft,
        verticalAlign: verticalAlign,
        borderRadius: shape === 'square' ? '8px' : '',
        borderColor: `${theme.border.inverse} !important`
      }}
      style={style}
      alt={preferredName}
    >
      {avatarInitials || (preferredName ? preferredName.charAt(0).toUpperCase() : '')}
    </Avatar>
  );

  if (tooltip) {
    return (
      <FltTooltip
        title={
          <ToolTipContainerNew theme={theme}>
            <div className="content">
              {avatar}
              <div className="name-and-email-div">
                <FltText variant="title-small" textColor="content-primary">
                  {`${firstName || ''} ${lastName || ''} ${preferredName ? `(${preferredName})` : ''}`}
                </FltText>
              </div>
            </div>
          </ToolTipContainerNew>
        }
      >
        {avatar}
      </FltTooltip>
    );
  }

  return avatar;
};

export default CustomAvatar;