import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Address } from '@src/dbSyncManager/tables/address.entity';
import { Email } from '@src/dbSyncManager/tables/email.entity';
import { Party } from '@src/dbSyncManager/tables/parties.entity';

interface AddressesState {
  mapData: Map<number | null, any>;
  arrayData: any[];
}

const initialState: AddressesState = {
  mapData: new Map(),
  arrayData: [],
};

const addressesSlice = createSlice({
  name: 'addresses',
  initialState,
  reducers: {
    setAddresses(state, action: PayloadAction<Address[]>) {
      state.arrayData = action.payload;
      state.mapData = new Map(action.payload.map((item) => [item.id, item]));
    },
  },
});

export const { setAddresses } = addressesSlice.actions;
export default addressesSlice.reducer;
