// Define the colors object with explicit typing
import { Theme } from '@src/styles/themes/themeInterface';
import styled, { css, CSSObject, RuleSet } from 'styled-components';
import { ButtonProps, Shape, Size, Type } from './fltIconButtonInterface';

// Define the sizes object with explicit typing
const sizes: { [key in Size]: RuleSet<object> } = {
  medium: css`
    width: var(--Spacing-9, 28px);
    height: var(--Spacing-9, 28px);
    padding: var(--spacing-0-75x, 6px);
    svg {
      font-size: 16px;
    }
  `,
  small: css`
    display: flex;
    width: 24px;
    height: 24px;
    padding: var(--spacing-0-50x, 4px);
    svg {
      font-size: 14px;
    }
  `,
  'x-small': css`
    display: flex;
    width: 20px;
    height: 20px;
    padding: var(--spacing-0-50x, 4px);
    svg {
      font-size: 14px;
    }
  `,

  large: css`
    width: var(--spacing-4x, 32px);
    height: var(--spacing-4x, 32px);
    padding: var(--spacing-1x, 8px);
    svg {
      font-size: 16px;
    }
  `,
};

const shapeStyles: { [key in Shape]: RuleSet<object> } = {
  square: css`
    border-radius: 6px;
  `,
};

// Define color styles
const iconStyles = {
  primary: css<{ theme: Theme }>`
    background: ${({ theme }) => theme.surface.background.secondary};
    color: ${({ theme }) => theme.content.tertiary};
    border: ${({ theme }) => `1px solid ${theme.border.secondary}`};
    &:hover {
      border-color: ${({ theme }) => theme.border.primary};
      color: ${({ theme }) => theme.content.primary};
    }

    &:active {
      border-color: ${({ theme }) => theme.border.primary};
      color: ${({ theme }) => theme.content.primary};
      background: ${({ theme }) => theme.surface.button.secondary.active};
    }

    &:disabled {
    }
  `,
  'no-border': css<{ theme: Theme }>`
    background: ${({ theme }) => theme.surface.background.secondary};
    color: ${({ theme }) => theme.content.tertiary};
    border: none;
    &:hover {
      background: linear-gradient(
          0deg,
          var(--surface-button-secondary-active, rgba(0, 12, 25, 0.04)) 0%,
          var(--surface-button-secondary-active, rgba(0, 12, 25, 0.04)) 100%
        ),
        var(--surface-background-secondary, #fff);
      color: ${({ theme }) => theme.content.primary};
    }

    &:active {
      color: ${({ theme }) => theme.content.primary};
      background: linear-gradient(
          0deg,
          var(--surface-button-secondary-active, rgba(0, 12, 25, 0.04)) 0%,
          var(--surface-button-secondary-active, rgba(0, 12, 25, 0.04)) 100%
        ),
        var(--surface-background-secondary, #fff);
    }

    &:disabled {
    }
  `,
};

const getButtonStyles = (type: Type) => {
  return iconStyles[type];
};

export const FltButtonWrapper = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 150ms ease-in-out;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  z-index: 0;
  ${({ btnType = 'primary' }) => getButtonStyles(btnType)}
  ${({ shape = 'square' }) => shapeStyles[shape]}
  ${({ size = 'large' }) => sizes[size]}

  ${({ btnStatus }) =>
    btnStatus === 'active'
      ? css`
          border-color: ${({ theme }) => theme.border.primary};
        `
      : ``}
      
  ${({ isActive }) =>
    isActive === true
      ? css`
          border-color: ${({ theme }) => theme.border.primary};
          color: ${({ theme }) => theme.content.primary};
          background: ${({ theme }) => theme.surface.button.secondary.active};
        `
      : ''}

${({ borderNone }) =>
    borderNone === true
      ? css`
          /* border-color: ${({ theme }) => theme.border.primary}; */
          border: none;
        `
      : ``}


  &:focus {
    outline: none;
  }

  ${({ styles }) => styles && css(styles as CSSObject)}
`;
