import { Badge } from '@mui/material';
import Menu from '@mui/material/Menu';
import { AppState } from '@src/app/rootReducer';
import logoDark from '@src/assets/images/empty-notification-dark.svg';
import logoLight from '@src/assets/images/empty-notification-light.svg';
import FltButton from '@src/components/base/buttons/fltButton/fltButton';
import FltIconButton from '@src/components/base/buttons/fltIconButton/fltIconButton';
import FltIcon from '@src/components/base/fltIcon/fltIcon';
import FltText from '@src/components/base/fltText/fltText';
import { ProcessedEventMessage } from '@src/dbSyncManager/tables/processedEventMessage.entity';

import { EventIconName } from '@src/pages/inbox/routes/inbox/data';
import { Theme } from '@src/styles/themes/themeInterface';
import { formatTimestampShort } from '@src/utils/common';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomAvatar from '../avatar/avatar';
import { NotificationMenuWrapper } from './notificationMenuStyle';
import { GroupsMessageGenerator } from '@src/pages/inbox/routes/inbox/components/messageFormater/groupsMessageGenerator';

export const NotificationMenu: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let theme: Theme = useSelector((state: AppState) => state.theme.theme);

  let userId = useSelector((state: AppState) => state.auth.userId);

  const handleMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuButtonClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const navButtonData = [
    { id: 1, label: 'All' },
    { id: 2, label: 'Mentioned' },
    { id: 3, label: 'Requests' },
  ];
  const handleNavButtonClick = (id: number, label: string) => {};

  const processedEventMessagesMap = useSelector(
    (state: AppState) => state.processedEventMessage.mapData
  );
  const publishedEventsMap = useSelector((state: AppState) => state.publishedEvents.mapData);
  const processedEventMessages = useSelector(
    (data: AppState) => data.processedEventMessage.arrayData
  );
  const publishedEvents = useSelector((data: AppState) => data.publishedEvents.arrayData);
  const teams = useSelector((data: AppState) => data.teams.arrayData);
  const users = useSelector((data: AppState) => data.users.arrayData);
  const teamsMap = useSelector((data: AppState) => data.teams.mapData);
  const usersMap = useSelector((data: AppState) => data.users.mapData);

  const currentUserId = useSelector((state: AppState) => state.auth.userId);
  const [notificationLists, setNotificationLists] = useState<ProcessedEventMessage[]>([]);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  useEffect(() => {
    const count = notificationLists.filter(
      (data) => data.status !== 'READ' && data.status !== 'DELETED'
    ).length;
    setUnreadCount(count);
  }, [notificationLists]);

  useEffect(() => {
    const newMessages = processedEventMessages.filter(
      (message) => message?.createdById !== currentUserId && message.status !== 'DELETED'
    );

    const sortedNotifications = newMessages.sort(
      (a, b) => new Date(b.timeCreated).getTime() - new Date(a.timeCreated).getTime()
    );

    setNotificationLists(sortedNotifications);
  }, [processedEventMessages, publishedEvents, currentUserId]);
  return (
    <div>
      <span onClick={handleMenuButtonClick}>
        <FltIconButton size="medium" name="BellSimple" />
      </span>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onClick={handleMenuClick}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            mt: 1.5,
            width: '376px',
            backgroundColor: theme.surface.background.secondary,
            border: `1px solid ${theme.border.secondary}`,
            boxShadow: theme.boxshadow.small,
            padding: '0px',
          },
        }}
        MenuListProps={{
          sx: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <NotificationMenuWrapper theme={theme}>
          <div className="header">
            <FltText variant="heading-medium" textColor="content-primary">
              Notification ({unreadCount} unread messages)
            </FltText>
            {/* <FltIconButton
              name="X"
              size="small"
              borderNone={true}
              onClick={() => handleMenuClose()}
            /> */}
          </div>
          {/* <div className="toggle-button-container">
            <FltToggleButton
              buttons={navButtonData}
              initialSelectedId={1}
              onButtonClick={handleNavButtonClick}
              variant="direct"
            />

          </div> */}
          <div className="content-container-heading">
            <FltText variant="title-medium" textColor="content-tertiary">
              Recent Messages
            </FltText>
          </div>
          <div className="content-container">
            {notificationLists &&
              publishedEventsMap &&
              publishedEventsMap.size > 0 &&
              notificationLists
                .slice(0, 5)
                .map((processMessageData: ProcessedEventMessage, index) => {
                  const notificationData = publishedEventsMap.get(
                    processMessageData.publishedEventId
                  );

                  const initiatorUser = usersMap.get(notificationData?.createdById);
                  const initiatorName =
                    notificationData?.createdById == currentUserId
                      ? 'you'
                      : initiatorUser?.firstName;
                  return (
                    <div
                      className="card-container"
                      key={index}
                      onClick={() => {
                        navigate(`/inbox/${processMessageData?.id}`);
                        handleMenuClose();
                      }}
                    >
                      <div className="card-details-container">
                        <Badge
                          overlap="circular"
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          badgeContent={
                            <span className="avator-icon-badge-container">
                              <FltIcon
                                size={14}
                                name={
                                  EventIconName[
                                    notificationData?.eventName as keyof typeof EventIconName
                                  ]
                                }
                                iconColor="content-secondary"
                              />
                            </span>
                          }
                        >
                          <CustomAvatar
                            avatarInitials={initiatorUser ? initiatorUser.avatarInitials : ''}
                            bgcolor={initiatorUser ? initiatorUser.avatarColor : ''}
                            width="24px"
                            height="24px"
                            fontSize="10px"
                          />
                        </Badge>
                        <div className="right-card-container">
                          <div className="first-container">
                            <div className="card-primary-content">
                              <FltText
                                variant={
                                  processMessageData.status != 'READ'
                                    ? 'heading-small'
                                    : 'title-small'
                                }
                                textColor={
                                  processMessageData.status != 'READ'
                                    ? 'content-primary'
                                    : 'content-static'
                                }
                                isEllipsis={true}
                              >
                                {initiatorName}
                              </FltText>
                            </div>
                            <div className="card-secondary-content">
                              <GroupsMessageGenerator
                                processedEventMessageData={processMessageData}
                                initiatorNameNone={true}
                                textColor={
                                  processMessageData.status != 'READ'
                                    ? 'content-secondary'
                                    : 'content-static'
                                }
                              />
                            </div>
                          </div>
                          <div className="second-container">
                            <FltText
                              variant={
                                processMessageData.status != 'READ'
                                  ? 'heading-small'
                                  : 'title-small'
                              }
                              isEllipsis={true}
                              textColor={
                                processMessageData.status != 'READ'
                                  ? 'content-primary'
                                  : 'content-static'
                              }
                            >
                              {formatTimestampShort(notificationData.timeCreated)}
                            </FltText>
                            {processMessageData.status !== 'READ' &&
                              processMessageData.status !== 'DELETED' && (
                                <div className="blue-badge"></div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            {notificationLists.length === 0 && (
              <div className="no-data-wrapper">
                <div className="no-data-container">
                  <img src={theme.themeType == 'light' ? logoLight : logoDark} alt="" />
                  <div className="no-data-text-content">
                    <FltText variant="heading-small" textColor="content-primary">
                      You're all caught up! No new notifications for now.
                    </FltText>
                    <FltText
                      variant="body-small"
                      textColor="content-tertiary"
                      styles={{ textAlign: 'center' }}
                    >
                      There are no new notifications at the moment. We'll keep you updated when
                      something comes up
                    </FltText>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="footer-conatiner">
            {/* <FltText variant="body-medium" textColor="content-tertiary">
              Showing the latest 5 unread notifications.
            </FltText> */}
            <FltButton
              type="secondary"
              size="small"
              onClick={() => {
                navigate('/inbox');
                handleMenuClose();
              }}
            >
              View all notification in inbox
            </FltButton>
          </div>
        </NotificationMenuWrapper>
      </Menu>
    </div>
  );
};
