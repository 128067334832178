import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserRelships } from '@src/dbSyncManager/tables/userRelships.entity';

interface UserRelshipState {
  mapData: Map<number | null, any>;
  arrayData: any[];
}

const initialState: UserRelshipState = {
  mapData: new Map(),
  arrayData: [],
};

const userRelshipSlice = createSlice({
  name: 'user_relship',
  initialState,
  reducers: {
    setUserRelship(state, action: PayloadAction<UserRelships[]>) {
      state.arrayData = action.payload;
      state.mapData = new Map(action.payload.map((item) => [item.id, item]));
    },
  },
});

export const { setUserRelship } = userRelshipSlice.actions;
export default userRelshipSlice.reducer;
