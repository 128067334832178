import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Party } from '@src/dbSyncManager/tables/parties.entity';

interface PartyState {
  mapData: Map<number | null, any>;
  arrayData: any[];
}

const initialState: PartyState = {
  mapData: new Map(),
  arrayData: [],
};

const partiesSlice = createSlice({
  name: 'parties',
  initialState,
  reducers: {
    setParties(state, action: PayloadAction<Party[]>) {
      state.arrayData = action.payload;
      state.mapData = new Map(action.payload.map((item) => [item.id, item]));
    },
  },
});

export const { setParties } = partiesSlice.actions;
export default partiesSlice.reducer;
