import { ProcessedEventMessageStatus } from '@src/constants/enum';
import { ProcessedEventMessage } from '@src/dbSyncManager/tables/processedEventMessage.entity';
import { HandleAlertClick } from '@src/pages/settings/routes/profile/profileFunctions';
import { checkTokenExpiry } from '@src/utils/common';
import { Dispatch, SetStateAction } from 'react';

interface ParamsType {
  handleProcessedMessageStatuUpdate: (
    status: ProcessedEventMessageStatus,
    notificationId: string,
    handleAlertClick: HandleAlertClick
  ) => void;
  notificationActionOptionData: ProcessedEventMessage;
  notificationId: string;
  handleAlertClick: HandleAlertClick;
}

export const avatorFakeData = [
  {
    avatarColor: '#BE3535',
    avatarInitials: 'JS',
    // preferredName: string,
    firstName: 'Jaya',
    lastName: 'Suriya',
  },
  {
    avatarColor: '#D46E0F',
    avatarInitials: 'RS',
    // preferredName: string,
    firstName: 'Jaya',
    lastName: 'Suriya',
  },
  {
    avatarColor: '#02AE47',
    avatarInitials: 'KJ',
    // preferredName: string,
    firstName: 'Jaya',
    lastName: 'Suriya',
  },
  {
    avatarColor: '#BE3535',
    avatarInitials: 'VK',
    // preferredName: string,
    firstName: 'Jaya',
    lastName: 'Suriya',
  },
  {
    avatarColor: '#024EAE',
    avatarInitials: 'TT',
    // preferredName: string,
    firstName: 'Jaya',
    lastName: 'Suriya',
  },
  {
    avatarColor: '#D46E0F',
    avatarInitials: 'JS',
    // preferredName: string,
    firstName: 'Jaya',
    lastName: 'Suriya',
  },
  {
    avatarColor: '#BE3535',
    avatarInitials: 'JS',
    // preferredName: string,
    firstName: 'Jaya',
    lastName: 'Suriya',
  },
  {
    avatarColor: '#BE3535',
    avatarInitials: 'JS',
    // preferredName: string,
    firstName: 'Jaya',
    lastName: 'Suriya',
  },
];

export const notificationFilterBtnOptions = [
  {
    name: () => {
      return 'All';
    },
    onClick: async (params: object) => {
      try {
        if (checkTokenExpiry()) {
          (
            params as {
              otherData: { setNotificationFilterOption: Dispatch<SetStateAction<string>> };
            }
          ).otherData.setNotificationFilterOption('All');
        }

        // alert(' All working');
      } catch (error) {
        checkTokenExpiry();
        console.log(error);
      }
    },
  },
  {
    name: () => {
      return 'Read';
    },
    onClick: async (params: object) => {
      try {
        if (checkTokenExpiry()) {
          (
            params as {
              otherData: { setNotificationFilterOption: Dispatch<SetStateAction<string>> };
            }
          ).otherData.setNotificationFilterOption('Read');
        }
        // alert('Owners working');
        // setMemberFilterOption("OWNER")
      } catch (error) {
        checkTokenExpiry();
        console.log(error);
      }
    },
  },
  {
    name: () => {
      return 'Unread';
    },
    onClick: async (params: object) => {
      try {
        if (checkTokenExpiry()) {
          (
            params as {
              otherData: { setNotificationFilterOption: Dispatch<SetStateAction<string>> };
            }
          ).otherData.setNotificationFilterOption('Unread');
        }
        // alert('Members working');
      } catch (error) {
        checkTokenExpiry();
        console.log(error);
      }
    },
  },
  {
    name: () => {
      return 'Deleted';
    },
    onClick: async (params: object) => {
      try {
        if (checkTokenExpiry()) {
          (
            params as {
              otherData: { setNotificationFilterOption: Dispatch<SetStateAction<string>> };
            }
          ).otherData.setNotificationFilterOption('Deleted');
        }
        // alert('Members working');
      } catch (error) {
        checkTokenExpiry();
        console.log(error);
      }
    },
  },
];

export const notificationActionBtnOptions = [
  {
    name: (params: ParamsType) => {
      return `Mark as ${params.notificationActionOptionData.status == 'READ' ? 'unread' : 'read'} `;
    },
    onClick: async (params: ParamsType) => {
      try {
        if (checkTokenExpiry()) {
          params.handleProcessedMessageStatuUpdate(
            params.notificationActionOptionData.status == 'READ'
              ? ProcessedEventMessageStatus.CREATED
              : ProcessedEventMessageStatus.READ,
            String(params.notificationActionOptionData.id),
            params.handleAlertClick
          );
        }
      } catch (error) {
        checkTokenExpiry();
        console.log(error);
      }
    },
  },
  {
    name: () => {
      return 'Delete notification';
    },
    onClick: async (params: ParamsType) => {
      try {
        if (checkTokenExpiry()) {
          params.handleProcessedMessageStatuUpdate(
            ProcessedEventMessageStatus.DELETED,
            String(params.notificationActionOptionData.id),
            params.handleAlertClick
          );
        }
      } catch (error) {
        checkTokenExpiry();
        console.log(error);
      }
    },
  },
];

export const EventIconName = {
  EVENT_TEAM_MEMBER_ADDED: 'UserCirclePlus',
  EVENT_TEAM_MEMBER_REMOVED: 'UserCircleMinus',
  EVENT_TEAM_SETTING_UPDATED: 'GearSix',
  EVENT_TEAM_DEPENDENT_ADDED: 'TreeView',
  EVENT_TEAM_DEPENDENT_REMOVED: 'TreeView',
  EVENT_TEAM_MEMBER_ROLE_UPDATED: 'UserSwitch',
};
