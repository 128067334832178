import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';

interface LocalStorageContextType {
  getLocalStorageData: (key: string) => any;
  setLocalStorageData: (key: string, value: any) => void;
}

const LocalStorageContext = createContext<LocalStorageContextType | undefined>(undefined);

interface LocalStorageProviderProps {
  children: ReactNode;
}

const LocalStorageProvider: React.FC<LocalStorageProviderProps> = ({ children }) => {
  const [storageData, setStorageData] = useState<{ [key: string]: any }>({});

  const getLocalStorageData = useCallback((key: string) => {
    return storageData[key];
  }, [storageData]);

  const setLocalStorageData = useCallback((key: string, value: any) => {
    setStorageData(prevData => ({ ...prevData, [key]: value }));
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(`Error setting localStorage item ${key}: `, error);
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key) {
        try {
          const parsedValue = event.newValue ? JSON.parse(event.newValue) : null;
          setStorageData(prevData => ({
            ...prevData,
            [event.key as string]: parsedValue,
          }));
        } catch (error) {
          console.error(`Error parsing localStorage key ${event.key}: `, error);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);


  return (
    <LocalStorageContext.Provider value={{ getLocalStorageData, setLocalStorageData }}>
      {children}
    </LocalStorageContext.Provider>
  );
};

const useLocalStorageContext = () => {
  const context = useContext(LocalStorageContext);
  if (context === undefined) {
    throw new Error('useLocalStorageContext must be used within a LocalStorageProvider');
  }
  return context;
};

export { LocalStorageProvider, useLocalStorageContext };

