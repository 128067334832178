import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Groups } from '@src/dbSyncManager/tables/groups.entity';

interface GroupsState {
  mapData: Map<number | null, any>;
  arrayData: any[];
}

const initialState: GroupsState = {
  mapData: new Map(),
  arrayData: [],
};

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setGroups(state, action: PayloadAction<Groups[]>) {
      state.arrayData = action.payload;
      state.mapData = new Map(action.payload.map((item) => [item.id, item]));
    },
  },
});

export const { setGroups } = groupsSlice.actions;
export default groupsSlice.reducer;
