import Tooltip from '@mui/material/Tooltip';
import { AppState } from '@src/app/rootReducer';
import FltIcon from '@src/components/base/fltIcon/fltIcon';
import FltText from '@src/components/base/fltText/fltText';
import { hexToRGBA, removeTrailingColon } from '@src/utils/common';
import { searchEmojis } from '@src/utils/emojiSearch';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SidebarItemsWrapper } from './sidebarItemStyle';
export interface SidebarItem {
  id: string;
  title: string;
  type?:string;
  route?: string;
  children?: SidebarItem[];
  icon: string;
  iconSource?: string;
  iconColor?: string;
  iconBg?: string;
}
interface SidebarItemProps {
  item: SidebarItem;
  activeItem: string;
  setActiveItem: (id: string) => void;
  collapsed: boolean;
}

const SidebarMenuItem: React.FC<SidebarItemProps> = ({
  item,
  activeItem,
  setActiveItem,
  collapsed,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [hoveredIcon, setHoveredIcon] = useState<string | null>(null);
  const navigate = useNavigate();
  const themes = useSelector((state: AppState) => state.theme.theme);
  const themeMode = useSelector((state: AppState) => state.theme.mode);
  const [emoji, setEmoji] = useState<string | null>(null);

  const handleMouseEnter = (iconName: string) => {
    setHoveredIcon(iconName);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };

  const handleItemClick = () => {
    if (item.route) {
      navigate(item.route);
      setActiveItem(item.id);
    }
    if (item.children && item.children.length > 0) {
      setIsExpanded(!isExpanded);
      handleSectionToggle(item.id);
    }
  };
  const iconNameContainerStyle = {
    // boxShadow: item.id === activeItem && themeMode === "light"  ? "0px 124px 35px 0px rgba(176, 176, 176, 0.00), 0px 79px 32px 0px rgba(176, 176, 176, 0.01), 0px 45px 27px 0px rgba(176, 176, 176, 0.05), 0px 20px 20px 0px rgba(176, 176, 176, 0.09), 0px 5px 11px 0px rgba(176, 176, 176, 0.10)" : "",

    // backgroundColor : item.id === activeItem ? "red" :"",
    // border:${({ theme ,mode}) => mode == "dark" ? `1px solid ${theme.pages.layout.sidebar.border.primary}` : "";
    // backgroundColor : item.id === activeItem ? themes.pages.layout.sidebar.icon.tertiary : hoveredIcon === item.icon ? themes.pages.layout.sidebar.icon.secondary : themes.pages.layout.sidebar.icon.primary
    backgroundColor:
      item.id === activeItem
        ? themeMode == 'light'
          ? '#d9dde1'
          : 'rgba(255, 255, 255, 0.08)'
        : '',
    marginLeft: item.id.includes('-') ? '7px' : '',
  };

  const [menuSelectOption, setMenuSelectionOption] = useState<null | object[]>(null);
  useEffect(() => {
    if (item.children) {
      const menuData = item.children.map((data) => {
        return {
          name: () => data.title,
          onClick: () => {
            if (data.route) navigate(data.route);
          },
        };
      });
      setMenuSelectionOption(menuData);
    }
  }, [item]);

  const [navItemBtnAnchorEl, setNavItemBtnAnchorEl] = useState<null | HTMLElement>(null);
  const [openNavItemBtn, setOpenNavItemBtn] = useState(false);

  const navItemMenuData = {
    otherData: {},
  };

  const handleNavItemClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setOpenNavItemBtn((prevOpen: boolean) => !prevOpen);
    setNavItemBtnAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const fetchEmoji = async () => {
      if (item.icon && item.icon.endsWith(':')) {
        const emojiResult = await searchEmojis(removeTrailingColon(item.icon));
        setEmoji(emojiResult);
      } else {
        setEmoji(null);
      }
    };

    fetchEmoji();
  }, [item.icon]);

  const [visibleSections, setVisibleSections] = useState<{ [key: string]: boolean }>({});
  const handleSectionToggle = (title: string) => {
    setVisibleSections((prevState) => ({
      ...prevState,
      [title]: !prevState[title],
    }));
  };

  return (
    <SidebarItemsWrapper
      theme={themes}
      mode={themeMode}
      collapsed={collapsed}
      style={{ padding: item.id.includes('-') ? '0px' : '0px 0px 0px 0px' }}
    >
      <Tooltip title={item.title} placement="right">
        <>
          {isExpanded && item.children && <div className="hierarchy-ver-line"></div>}
          {item.id.includes('-') && <div className="hierarchy-har-line"></div>}
          <div
            className="parent-item"
            style={iconNameContainerStyle}
            onClick={handleItemClick}
            onMouseEnter={() => handleMouseEnter(item.icon)}
            onMouseLeave={handleMouseLeave}
          >
            <div
              // style={{ color: themes.pages.layout.sidebar.text.secondary,}}
              style={{
                color: item.id.includes('-')
                  ? themes.pages.layout.sidebar.text.tertiary
                  : themes.pages.layout.sidebar.text.secondary,
              }}
              className="icon-name-container"
            >
              <div
                className="icon-wrapper"
                style={{ backgroundColor: item.iconColor ? hexToRGBA(item.iconColor) : '' }}
              >
                {item.icon && item.icon.endsWith(':') ? (
                  emoji
                ) : (
                  <FltIcon
                    name={item.icon}
                    source={item.iconSource == 'local' ? 'local' : 'phosphor-icons'}
                    size={16}
                    color={item.iconColor}
                    weight={
                      (item.id == activeItem ||
                      (item.id && hoveredIcon == item.icon)) && item.type != 'Teams'
                        ? 'fill'
                        : 'regular'
                    }
                  />
                )}
              </div>
              <div className="text-wrapper">
                <FltText variant={'heading-medium'}>{item.title}</FltText>
              </div>
            </div>
            {item.children && (
              <div className="right-side-item">
                <FltIcon
                  name={visibleSections[item.id] ? 'CaretDown' : 'CaretRight'}
                  weight="fill"
                  size={12}
                  iconColor="content-static"
                />
              </div>
            )}
          </div>
        </>
      </Tooltip>

      {isExpanded && item.children && (
        <div className="child-item-container">
          {item.children.map((child) => (
            <SidebarMenuItem
              collapsed={collapsed}
              key={child.id}
              item={child}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
            />
          ))}
        </div>
      )}
    </SidebarItemsWrapper>
  );
};

export default SidebarMenuItem;
