import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Payment } from '@src/dbSyncManager/tables/payment.entity';

interface PaymentState {
  mapData: Map<number | null, any>;
  arrayData: any[];
}

const initialState: PaymentState = {
  mapData: new Map(),
  arrayData: [],
};

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setPayments(state, action: PayloadAction<Payment[]>) {
      state.arrayData = action.payload;
      state.mapData = new Map(action.payload.map((item) => [item.id, item]));
    },
  },
});

export const { setPayments } = paymentsSlice.actions;
export default paymentsSlice.reducer;
