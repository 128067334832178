export enum AllowedStatus {
  ACTIVE = 'ACTIVE',
  IN_ACTIVE = 'INACTIVE',
}

export enum AllowedCurrency {
  INR = 'INR',
  USD = 'USD',
  SGD = 'SGD',
}

export enum paymentMethod {
  CREDIT_CARD = 'CREDIT_CARD',
  BANK_TRANSFER = 'BANK_TRANSFER',
  CASH = 'CASH'
}

export enum PartyType {
  CUSTOMER = 'CUSTOMER',
  VENDOR ="VENDOR"
}

export enum DocumentType {
  INVOICE = 'INVOICE',
  BILL = 'BILL',
}

export enum AllowedPhoneOrEmailVerificationMethod {
  OTP = 'OTP',
}

export enum AllowedBusinessType {
  MANUFACTURE = 'MANUFACTURE',
  SERVICE = 'SERVICE',
}

export enum UserType {
  GOOGLEUSER = 'GOOGLEUSER',
  FLUTEUSER = 'FLUTEUSER',
}

export enum AllowedPlatforms {
  WEB = 'WEB',
  ANDROID = 'ANDROID',
  IOS = 'iOS',
}

export enum BudgetForecastType {
  BUDGET = 'BUDGET',
  FORECAST = 'FORECAST',
}

export enum FinancialType {
  EXPENSE = 'EXPENSE',
  INCOME = 'INCOME',
}

export enum LedgerCategory {
  ASSETS = 'ASSETS',
  LIABILITIES = 'LIABILITIES',
  INCOME = 'INCOME',
  EXPENSE = 'EXPENSE',
  EQUITY = 'EQUITY',
}

export enum PeriodType {
  FINANCIAL = 'FINANCIAL',
}

export enum ScheduledNotificationState {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum ALLOWED_SCHEDULED_NOTIFICATION_ENTITIES {
  INVOICE = 'INVOICE',
}

export enum ALLOWED_NOTIFICATION_TYPE {
  EMAIL = 'EMAIL',
  IN_APP_NOTIFICATION = 'IN_APP_NOTIFICATION',
}

export enum ALLOWED_NOTIFICATION_STATE {
  SENT = 'SENT',
  DELIVERED = 'DELIVERED',
  READ = 'READ',
}
export enum UserRole {
  WORKSPACEADMIN = 'WORKSPACEADMIN',
  MEMBER = 'MEMBER',
}

export enum INVOICE_STATE {
  DRAFT = 'DRAFT',
  APPROVED = 'APPROVED',
  PAID_FULL = 'PAID_FULL',
  PAYMENT_DUE = 'PAYMENT_DUE',
}

export enum ApprovalDecisionType {
  ACTIVE = 'ACTIVE',
  IN_ACTIVE = 'INACTIVE',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum GroupOperation {
  GROUP_PARENT_ADD = 'GROUP_PARENT_ADD',
  GROUP_PARENT_DELETE = 'GROUP_PARENT_DELETE',
  GROUP_CHILD_ADD = 'GROUP_CHILD_ADD',
}

export enum GroupNotificationOperation {
  GROUP_PARENT_ADD = 'GROUP_ADD_PARENT',
  GROUP_PARENT_DELETE = 'GROUP_DELETE_PARENT',
  GROUP_CHILD_ADD = 'GROUP_CHILD_ADD',
  GROUP_CHILD_DELETE = 'GROUP_CHILD_DELETE',
}

export enum NotificationEventType {
  TEAM_MEMBER_ADDED = 'EVENT_TEAM_MEMBER_ADDED',
  TEAM_MEMBER_REMOVED = 'EVENT_TEAM_MEMBER_REMOVED',
  TEAM_SETTING_UPDATED = 'EVENT_TEAM_SETTING_UPDATED',
  TEAM_DEPENDENT_ADDED = 'EVENT_TEAM_DEPENDENT_ADDED',
  TEAM_DEPENDENT_REMOVED = 'EVENT_TEAM_DEPENDENT_REMOVED',
  TEAM_MEMBER_ROLE_UPDATED = 'EVENT_TEAM_MEMBER_ROLE_UPDATED',
  INITIATIVE_SETTING_UPDATED = 'EVENT_INITIATIVE_SETTING_UPDATED',
  INITIATIVE_MEMBER_ADDED = 'EVENT_INITIATIVE_MEMBER_ADDED',
  INITIATIVE_MEMBER_REMOVED = 'EVENT_INITIATIVE_MEMBER_REMOVED',
  INITIATIVE_MEMBER_ROLE_UPDATED = 'EVENT_INITIATIVE_MEMBER_ROLE_UPDATED',
  INITIATIVE_WORKOBJECT_ADDED = 'EVENT_INITIATIVE_WORK_OBJECT_ADDED',
  INITIATIVE_WORKOBJECT_REMOVED='EVENT_INITIATIVE_WORK_OBJECT_REMOVED',
  INITIATIVE_WORKOBJECT_UPDATED='EVENT_INITIATIVE_WORK_OBJECT_UPDATED'
}

export enum VisibilityType {
  DEFAULT = 'DEFAULT',
  RESTRICTED = 'RESTRICTED',
}

export enum FinancalPositionType {
  BUDGET = 'BUDGET',
  FORECAST = 'FORECAST',
  ACTUALS = 'ACTUALS',
}

export enum Role {
  OWNER = 'OWNER',
  MEMBER = 'MEMBER',
  EDITOR = 'EDITOR',
}
export enum HierarchyType {
  ACCOUNT = 'ACCOUNT',
  INITIATIVE = 'INITIATIVE',
  PROGRAM = 'PROGRAM',
  TEAM = 'TEAM',
}
// UserRelshipType

export enum GroupType {
  INITIATIVE = 'INITIATIVE',
  PROGRAM = 'PROGRAM',
  TEAM = 'TEAM',
}

export enum ForType {
  ACCOUNT = 'ACCOUNT',
  USER = 'USER',
  PARTY = 'PARTY'
}
export enum AllowedType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export enum DiscountType {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE',
}
export enum PreferenceType {
  INVOICE = 'INVOICE',
  GENERAL = 'GENERAL',
}
export enum ProductType {
  GOODS = 'GOODS',
  SERVICES = 'SERVICES',
}

export const stateToDisplayNameMapping: { [key: string]: string } = {
  DRAFT: 'Draft',
  APPROVED: 'Approved',
  PAID_FULL: 'Paid',
  PAYMENT_DUE: 'Overdue',
};

export enum ProcessedEventMessageStatus {
  CREATED = 'CREATED',
  SEND = 'SEND',
  DELIVERED = 'DELIVERED',
  READ = 'READ',
  DELETED = 'DELETED'
}

export enum EventChannel {
  SMS = 'SMS',
  SLACK = 'SLACK',
  EMAIL = 'EMAIL',
  INBOX = 'INBOX',
}

export enum EventsForType {
  INVOICE = 'INVOICE',
  TEAMS = 'TEAMS',
}
export enum EventsType {
  GENERAL = 'GENERAL',
  INTERNAL = 'INTERNAL',
}
export enum UserAction {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
}

export enum PublishedEventStatus {
  CREATED = 'CREATED',
  IN_PROGRESS = 'IN_PROGRESS',
  PROCESSED = 'PROCESSED',
}

export enum HistoryType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}