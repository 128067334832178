import { BorderClear } from '@mui/icons-material';
import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import { AppState } from '@src/app/rootReducer';
import { useSelector } from 'react-redux';

export const FltTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: useSelector((state: AppState) => state.theme.theme.surface.background.secondary), // Specified RootState for useSelector
    boxShadow: theme.shadows[1],
    borderRadius: 6,
    borderColor: useSelector((state: AppState) => state.theme.theme.border.secondary),
    fontSize: 11,
    padding:0,
  },
}));
