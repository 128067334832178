import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EventSubscriptions } from '@src/dbSyncManager/tables/eventSubscriptions.entity';
import { Groups } from '@src/dbSyncManager/tables/groups.entity';
import { ProcessedEventMessage } from '@src/dbSyncManager/tables/processedEventMessage.entity';
import { PublishedEvent } from '@src/dbSyncManager/tables/publishedEvents.entity';

interface EventSubscriptionsState {
  mapData: Map<number | null, any>;
  arrayData: any[];
}

const initialState: EventSubscriptionsState = {
  mapData: new Map(),
  arrayData: [],
};

const eventSubscriptionsSlice = createSlice({
  name: 'eventSubscriptions',
  initialState,
  reducers: {
    setEventSubscriptions(state, action: PayloadAction<EventSubscriptions[]>) {
      state.arrayData = action.payload;
      state.mapData = new Map(action.payload.map((item) => [item.id, item]));
    },
  },
});

export const { setEventSubscriptions } = eventSubscriptionsSlice.actions;
export default eventSubscriptionsSlice.reducer;
