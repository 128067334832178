import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Groups } from '@src/dbSyncManager/tables/groups.entity';
import { ProcessedEventMessage } from '@src/dbSyncManager/tables/processedEventMessage.entity';

interface ProcessedEventMessageState {
  mapData: Map<number, any>;
  arrayData: any[];
}

const initialState: ProcessedEventMessageState = {
  mapData: new Map(),
  arrayData: [],
};

const processedEventMessageSlice = createSlice({
  name: 'processedEventMessage',
  initialState,
  reducers: {
    setProcessedEventMessage(state, action: PayloadAction<ProcessedEventMessage[]>) {
      state.arrayData = action.payload;
      state.mapData = new Map(action.payload.map((item) => [item.id, item]));
    },
  },
});

export const { setProcessedEventMessage } = processedEventMessageSlice.actions;
export default processedEventMessageSlice.reducer;
