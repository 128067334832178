// src/redux/slices/authSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useFltSnackbar } from '@src/components/base/fltSnackBar/fltSnackBar';
import { actionCellRenderer } from '@src/pages/settings/routes/userAndManagement/functions/cellRenderers';

interface AuthState {
  isLoading: boolean;
  success: string|null;
  error: string | null;
}

const initialState: AuthState = {
  isLoading: false,
  success: null,
  error: null,
};
 
// Slice
const signupAPI = createSlice({
  name: 'signupAPI',
  initialState,
  reducers: {
    setPasswordRequest(state) {
      state.isLoading = true;
      state.error = null;
    },
    setPasswordSuccess(state,action) {
      state.isLoading = false;
      state.success = action.payload;
    },
    setPasswordFailure(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { setPasswordRequest, setPasswordSuccess, setPasswordFailure } = signupAPI.actions;

export default signupAPI.reducer;
