import { Theme } from '@src/styles/themes/themeInterface';
import styled from 'styled-components';

type PrimarySideBarWrapperProps = {
  theme: Theme;
  collapsed: boolean;
  openDrawerSideBar: boolean;
  mode: string;
};

export const PrimarySideBarWrapper = styled.div<PrimarySideBarWrapperProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.surface.background.primary};
  position: fixed;
  top: 0px;
  width: ${({ collapsed, openDrawerSideBar }) => (collapsed && !openDrawerSideBar ? '10px' : '220px')};
 // transition: width 0.5s ease-in-out; /* Use 0.5s instead of 0.5ms */
 transition: none;
  height: 100vh;
  /* overflow: visible; */
  overflow: hidden;
  /* border-right: 2px solid ${({ theme }) => theme.pages.layout.sidebar.border.secondary}; */
  user-select: none;
  .side-bar-hover-handler{
    width: 10px;
    height: 100%;
  }
  .content {
    width:100%;
    height: 100%;
    overflow-y: auto;
    scrollbar-gutter: stable;
    -webkit-overflow-scrolling: touch;
    padding: 0px 14px 0px 14px;
  }
  .content::-webkit-scrollbar {
    width: 8px;
  }
  .content::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }

  .sidebar-header {
    display: flex;
    width:220px;
   // transition: width 0.5s ease-in-out;
    height: 40px;
    padding: 8px 14px 8px 16px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    background-color: inherit;
    /* border-bottom: 2px solid ${({ theme }) => theme.pages.layout.sidebar.border.secondary}; */
    /* border-right: 1px solid ${({ theme }) => theme.pages.layout.sidebar.border.secondary}; */
    margin-top: 8px;

    .left-container {
      display: flex;
      align-items: center;
      gap: 6px;
      padding-left: 4px;
    }

    .right-container {
      display: flex;
      align-items: center;
      cursor: pointer;
      /* padding-right: 6px; */

      * {
        color: ${({ theme }) => theme.pages.layout.sidebar.icon.quaternary};
      }
    }
  }
  .create-btn-container {
    display: flex;
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    margin-bottom: 3px;
    /* margin-top: 8px;
    margin-bottom: 8px; */
    /* width: 100%; */
  }

  .divider {
    /* height: 1px;
  background-color: #CFD6DB;
  margin: 16px 0px;
  filter: drop-shadow(0px 1px 0px #FFF); */
    
    /* width: 220px; */
/* box-shadow: 0px 1px 0px #FFF; */
    height: 8px;
    /* background-color: ${({ theme }) => theme.pages.layout.sidebar.border.primary}; */
    
    margin: 0 auto;
    /* margin: 16px 16px; */
  }
  .divider-with-border {
    margin: 16px 0px;
    border-top: 1px solid ${({ theme }) => theme.pages.layout.sidebar.border.primary};
  }
  .side-bar-group-item-container{
    display: flex;
    flex-direction: column;
    gap: 1px;
  }
  .nav-item-group-title-container {
    display: flex;
    /* padding: 8px 24px 2px 24px; */
    padding: 4px;
    /* margin-top: 8px; */
    align-items: center;
    gap: 6px;
    /* align-self: stretch; */
    
    cursor: pointer;
    color: ${({ theme }) => theme.pages.layout.sidebar.text.quaternary};
    height: 24px;
    margin-bottom: 1px;
    border-radius: 4px;
    /* transition: background-color 0.3s; */
  }
  .nav-item-group-title-container:hover {
    /* color: ${({ theme }) => theme.pages.layout.sidebar.text.hover.primary}; */
    background-color: ${({ mode }) => mode == 'light' ? '#d9dde1' : 'rgba(255, 255, 255, 0.08)'}; 
  }
`;
