import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentLineItem } from '@src/dbSyncManager/tables/documentLineItem.entity';

interface DocumentLineItemState {
  mapData: Map<number | null, any>;
  arrayData: any[];
}

const initialState: DocumentLineItemState = {
  mapData: new Map(),
  arrayData: [],
};

const documentLineItemsSlice = createSlice({
  name: 'documentLineItems',
  initialState,
  reducers: {
    setDocumentLineItems(state, action: PayloadAction<DocumentLineItem[]>) {
      state.arrayData = action.payload;
      state.mapData = new Map(action.payload.map((item) => [item.id, item]));
    },
  },
});

export const { setDocumentLineItems } = documentLineItemsSlice.actions;
export default documentLineItemsSlice.reducer;