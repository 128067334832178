import React from 'react';
import FltText, { TextColorVariant, TextVariant } from '@src/components/base/fltText/fltText';
import { useSelector } from 'react-redux';
import { Theme } from '@src/styles/themes/themeInterface';
import { AppState } from '@src/app/rootReducer';
import FltIcon from '@src/components/base/fltIcon/fltIcon';
import { hexWithOpacity } from '@src/utils/common';

type MessageProps = {
  messageParts: any[];
  textVariant?: TextVariant;
  textColor?: TextColorVariant;
};

export const NotificationDisplay = ({
  messageParts,
  textVariant = 'label-small',
  textColor = 'content-tertiary',
}: MessageProps) => {
  const theme: Theme = useSelector((state: AppState) => state.theme.theme);

  return (
    <>
      {messageParts.map((data, index) => (
        <React.Fragment key={index}>
          {data.primaryConnector && (
            <FltText variant={textVariant} textColor={textColor} key="text">
              {data.primaryConnector}{' '}
            </FltText>
          )}
          {data.primaryIcon && (
            <FltIcon
              name={data.primaryIcon.icon}
              bg={true}
              color={
                textColor === 'content-static'
                  ? hexWithOpacity(data.primaryIcon.color, 0.5)
                  : data.primaryIcon.color
              }
              size={12}
            />
          )}
          {data.secondaryName && (
            <FltText variant={textVariant} textColor={textColor}>
              {data.secondaryName}{' '}
            </FltText>
          )}
          {data.secondaryConnector && (
            <FltText variant={textVariant} textColor={textColor}>
              {data.secondaryConnector}{' '}
            </FltText>
          )}
          {data.secondaryIcon && (
            <FltIcon
              name={data.secondaryIcon.icon}
              bg={true}
              color={
                textColor === 'content-static'
                  ? hexWithOpacity(data.secondaryIcon?.color, 0.5)
                  : data.secondaryIcon?.color
              }
              size={12}
            />
          )}
          {data.tertiaryName && (
            <FltText variant={textVariant} textColor={textColor}>
              {data.tertiaryName}{' '}
            </FltText>
          )}
          {data.tertiaryConnector && (
            <FltText variant={textVariant} textColor={textColor}>
              {data.tertiaryConnector}{' '}
            </FltText>
          )}
          {data.tertiaryIcon && (
            <FltIcon
              name={data.tertiaryIcon.icon}
              bg={true}
              color={
                textColor === 'content-static'
                  ? hexWithOpacity(data.tertiaryIcon.color, 0.5)
                  : data.tertiaryIcon.color
              }
              size={12}
            />
          )}
          {data.targetName && (
            <FltText variant={textVariant} textColor={textColor} isEllipsis={true} key="targetTeam">
              {data.targetName} team
            </FltText>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default NotificationDisplay;
