import { Theme } from '@src/styles/themes/themeInterface';
import styled from 'styled-components';

export const AvatarContainer = styled.div`
  .avatar-grp-div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2px;
  }
`;

export const ToolTipContainerNew = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: column;
  padding: 0 6px 6px 6px;
  align-items: flex-start;
  align-self: stretch;
  position: relative;
  border-radius: 6px;
  max-height: 350px;
  overflow-y: scroll;
  border: 1px solid ${({ theme }) => theme.border.secondary};
  scrollbar-color: ${({ theme }) => theme.surface.button.tertiary.hover} ${({ theme }) => theme.surface.button.tertiary.hover};

  .title-div {
    position: sticky;
    top: 0;
    z-index: 2;
    width: 100%;
    padding: var(--spacing-0-75x, 6px);
    background: ${({ theme }) => theme.surface.background.secondary};

  }

  .content {
    display: flex;
    margin-top: 6px;
    padding: var(--spacing-0-75x, 8px) 12px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    cursor: pointer;

    .name-and-email-div {
      display: flex;
      flex-direction: column;
    }

    &:hover {
      background-color: ${({ theme }) => theme.surface.background.hover};
      border-radius: 6px;
    }
  }
`;
