import { db } from '@src/dbSyncManager/init';
import { useLiveQuery } from 'dexie-react-hooks';



export function transformPath(inputPath: string): string {
  if (!inputPath) return inputPath; // Return as-is if input is empty or undefined

  const parts = inputPath.split('/').filter(Boolean); // Split the path and remove empty parts
  // Special case: If the path starts with "/groups/teams/details"
  if ( parts[2] === 'details') {
    return `/${parts[3]}/${parts[4]}`; // Return the specific sub-path
  }
if(parts?.length>=4){
  return `/${parts.splice(2)?.join('/')}`;
}
  // General cases
  if (parts?.length <= 2) {
    return inputPath; // Return the path as-is if it's short (e.g., "/groups/teams")
  }
  

  return inputPath; // Default case, return as-is
}