import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Periods } from '@src/dbSyncManager/tables/period.entity';

interface PeriodsState {
  mapData: Map<number | null, any>;
  arrayData: any[];
}

const initialState: PeriodsState = {
  mapData: new Map(),
  arrayData: [],
};

const periodsSlice = createSlice({
  name: 'periods',
  initialState,
  reducers: {
    setPeriods(state, action: PayloadAction<Periods[]>) {
      state.arrayData = action.payload;
      state.mapData = new Map(action.payload.map((item) => [item.id, item]));
    },
  },
});

export const { setPeriods } = periodsSlice.actions;
export default periodsSlice.reducer;
