import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentLineItemTax } from '@src/dbSyncManager/tables/documentLineItemTax.entity';

interface DocumentLineItemTaxState {
  mapData: Map<number | null, any>;
  arrayData: any[];
}

const initialState: DocumentLineItemTaxState = {
  mapData: new Map(),
  arrayData: [],
};

const documentLineItemTaxesSlice = createSlice({
  name: 'documentLineItemTaxes',
  initialState,
  reducers: {
    setDocumentLineItemTaxes(state, action: PayloadAction<DocumentLineItemTax[]>) {
      state.arrayData = action.payload;
      state.mapData = new Map(action.payload.map((item) => [item.id, item]));
    },
  },
});

export const { setDocumentLineItemTaxes } = documentLineItemTaxesSlice.actions;
export default documentLineItemTaxesSlice.reducer;