import { Theme } from '@src/styles/themes/themeInterface';
import styled from 'styled-components';

export const ProfileMenuWrapper = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .avator-container {
    display: flex;
    padding: var(--spacing-1x, 8px) var(--spacing-1-5x, 12px);
    align-items: center;
    gap: var(--spacing-1x, 8px);
    align-self: stretch;
    border-bottom: 1px solid ${({ theme }) => theme.border.tertiary};
    .avator-right-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2px;
      flex: 1 0 0;
    }
  }
  .menu-option-container {
    display: flex;
    padding: var(--spacing-1x, 8px) var(--spacing-0-75x, 6px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--spacing-0-50x, 4px);
    align-self: stretch;
    border-bottom: 1px solid ${({ theme }) => theme.border.tertiary};
  }

  .logout-container {
  }
  .menu-item-container {
    display: flex;
    height: 28px;
    padding: var(--spacing-0-75x, 6px);
    align-items: center;
    gap: var(--spacing-0-75x, 6px);
    align-self: stretch;
    border-radius: var(--spacing-0-75x, 6px);
    background: ${({ theme }) => theme.surface.button.tertiary.default};
    cursor: pointer;
  }
  .menu-item-container:hover {
    background: ${({ theme }) => theme.surface.background.hover};
  }
`;
