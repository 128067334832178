import { createGlobalStyle } from 'styled-components';

const Variables = createGlobalStyle`
/* typography font family */
@font-face {
  font-family: "Inter UI";
  font-weight: 400 800;
  font-display: swap;
  font-style: normal;
  src: url(https://static.linear.app/fonts/Inter-roman.var.woff2) format("woff2");
}

@font-face {
                font-family: "Inter UI";
                font-weight: 400 800;
                font-display: swap;
                font-style: italic;
                src: url(https://static.linear.app/fonts/Inter-italic.var.woff2) format("woff2")
            }

:root {
  --font-sans: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',                  
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    --font-inter-ui: 'Inter UI', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
 
  --font-serif: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  --font-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}



/* styles.css */
:root {

  --primary-90: #000c19;
  --primary-80: #002655;
  --primary-70: #004091;
  --primary-60: #024eae;
  --primary-50: #306fbe;
  --primary-40: #5e90ce;
  --primary-30: #8cb1de;
  --primary-20: #bad2ee;
  --primary-10: #e6f1ff;

  //opacity-colors
 --primary-100: #e6f1ff;



  --success-90: #016027;
  --success-80: #017c32;
  --success-70: #029e41;
  --success-60: #02ae47;
  --success-50: #35be6c;
  --success-40: #55c984;
  --success-30: #8bdaaa;
  --success-20: #b1e6c6;
  --success-10: #e6f7ed;
  --grey-bg-light: #131416;
  --grey-bg-dark: #0A0A0B;
  --grey-100: #0A0A0B;
  --grey-90: #131416;
  --grey-80: #1C1E21;
  --grey-70: #26282C;
  --grey-60: #494C50;
  --grey-50: #85898E;
  --grey-40: #AFB2B6;
  --grey-30: #CFD1D3;
  --grey-20: #EAEBEB;
  --grey-10: #F2F2F3;
  --grey-0: #FCFCFC;

  --alert-90: #602f01;
  --alert-80: #7c3c01;
  --alert-70: #9e4d02;
  --alert-60: #d46e0f;
  --alert-50: #be7735;
  --alert-40: #c98d55;
  --alert-30: #dab18b;
  --alert-20: #e6cab1;
  --alert-10: #f7eee6;
  --error-90: #600101;
  --error-80: #7c0101;
  --error-70: #9e0202;
  --error-60: #ca1212;
  --error-50: #be3535;
  --error-40: #c95555;
  --error-30: #da8b8b;
  --error-20: #e6b1b1;
  --error-10: #f7e6e6;
  --absolute-white: #ffffff;
  --absolute-black: #000000;
  
  // brand
  /* --primary: #006bff;
  --primary-10: #001533;
  --primary-20: #002a66;
  --primary-30: #004099;
  --primary-blue: #0055cc;
  --primary-60: #3388ff;
  --primary-70: #66a6ff;
  --primary-80: #99c3ff;
  --primary-90: #cce1ff; */
  /* --white: #FFFFFF;
  --black: #1C1C1C;
  --grey: #9b9898
  --highlight-red: #ff2d2d;
  --highlight-green: #01AF2E; */
  
  /* Light theme colors */
  --light-text-primary: #1C1C1C;    
  --light-text-secondary: #606260;
  --light-bg: #FBFBFB;
  //border-colors 
  --light-border-primary: #ECECEC;   
  --light-border-secondary: #F7F7F7;

  //Pages colors
  //text-colors:
  --light-pages-text-primary: #1C1C1C;
  --light-pages-text-secondary: #606260;
  //Background-colors:
  --light-pages-bg-primary: #FBFBFB;
  //Border-colors:
  --light-pages-border-primary:#ECECEC; 
--light-pages-border-secondary:#606260;
  //drop shadow
 --light-pages-dropShadow:#00000052;
 --light-pages-avatar-border-primary:#bdb9b9;
  //layout
  --light-pages-scroll-color:#556268;
  --light-pages-layout-border:#333740;
  --light-pages-layout-bg:#18191a;
  --light-pages-layout-text-primary:#ffffff;
  --light-pages-layout-hover-primary:#263238;
  //secondary-sidebar
  --light-pages-layout-secondarySidebar-bg:#5b5c5d33;
  --light-pages-layout-secondarySidebar-bg-hover-primary:#d3d3d3;
  // ***OldButton-Colors*** //
  --light-button-text-primary: #303230;
  --light-button-text-secondary: #FFFFFF;
  --light-button-bg-primary: #FFFFFF;
  --light-button-bg-secondary: #5A68CF;
  --light-button-border-primary: #E5E5E5;
  --light-button-border-secondary: #5563C7;

  /* *** FltButton *** */
  //Text-colors:
  --light-fltButton-text-primary:#FEFEFF;
  --light-fltButton-text-secondary: #303031;
  --light-fltButton-text-tertiary: #6D78D5;
  --light-fltButton-text-danger: #EB5656;
  //Background-colors:
  --light-fltButton-bg-primary: #6D78D5;
  --light-fltButton-bg-secondary: #FFFFFF;
  --light-fltButton-bg-danger: #EB5656;
  --light-fltButton-bg-hover-primary: #6671C9;
  --light-fltButton-bg-hover-secondary: #F7F7F7;
  --light-fltButton-bg-hover-danger: #DC484B;
  //Border-colors:
  --light-fltButton-border-primary: #6671C9;
  --light-fltButton-border-secondary: #D8D8D8;
  --light-fltButton-border-danger: #DC484B;
  //Box-shadow-colors:
  --light-fltButton-box-primary: #00000005;
  --light-fltButton-box-hover-primary: #bcc3f2;
  --light-fltButton-box-hover-secondary: #EBEBEB;
  --light-fltButton-box-hover-danger: #f9b1b1; 
  /* *** FltButton *** */
  //Text-colors
  --light-flt-input-text-primary : #1B1B1B;
  --light-flt-input-text-error: #D23F44;
  //Background-colors:
  --light-flt-input-bg-primary: #FBFBFB;
  //Border-colors:
  --light-fltInput-border-primary: #D4D4D4;
  --light-fltInput-border-error: #D23F44;
  --light-fltInput-border-hover-primary: #BDBDBD;
  --light-fltInput-border-hover-error: #D23F44;
  --light-fltInput-border-focus-primary: #6d78d5;
  --light-fltInput-border-focus-error: #D23F44;
  //BoxShadow colors;
  --light-fltInput-box-primary:#1b6cd20d;

 /**** / FltCheckbox  **** */
--light-fltCheckbox-checked-border-primary:#5E69D1;
--light-fltCheckbox-checked-bg-primary:#5E69D1;
--light-fltCheckbox-unchecked-border-primary:#5E69D1;
--light-fltCheckbox-checked-box-primary:#5E69D1;
--light-fltCheckbox-checked-box-secondary:#00000033;
--light-fltCheckbox-unchecked-box-primary:#5E69D1;
--light-fltCheckbox-unchecked-box-secondary:#00000033;
--light-fltSwitch-bg-primary:#dddddd;
--light-fltSwitch-box-primary:#cccccc;
/**** / FltRadio **** */
--light-fltRadio-checked-bg-primary: #6d78d5;
--light-fltRadio-checked-bg-hover:#6d78d5;
--light-fltRadio-checked-outline-primary:#6d78d5;
--light-fltRadio-checked-box-primary:#6d78d5;
--light-fltRadio-unchecked-bg-image-primary:#ffffffcc;
--light-fltRadio-unchecked-bg-image-secondary:#ffffff00;
--light-fltRadio-unchecked-bg-primary:#ced9e080;
--light-fltRadio-unchecked-bg-hover:#ebf1f5;
--light-fltRadio-unchecked-outline-primary: #137cbd99;
--light-fltRadio-unchecked-box-primary:#10161a33;
--light-fltRadio-unchecked-box-secondary:#10161a1a;
--light-fltRadio-checked-bg-image-primary:#ffffff1a;
--light-fltRadio-checked-bg-image-secondary:#ffffff00;

  /* *** FltModal *** */
  //text-colors;
  --light-fltModal-text-primary: #2F2F31;
  --light-fltModal-text-secondary: #A0A0A2;
  //background-colors:
  --light-fltModal-bg-primary: #FFFFFF;
  //border-colors:
  --light-fltModal-border-primary: #D8D8D8;
  //backdrop
   //backdrop
  --light-fltModal-backdrop-primary:#00000019
;
  //boxShadow-colors;
  --light-fltModal-box-primary: #0000001f;
  --light-fltModal-box-secondary: #0000000a;

   /* *** FltMenu *** */
  //menuItem-text-colors:
  --light-fltMenu-menuItem-text-primary: #5E5E5F;
  --light-fltMenu-menuItem-text-secondary: #A0A0A2;
  --light-fltMenu-menuItem-text-hover-primary: #1B1B1B;
  //menuItem-background-colors
 /* --light-fltMenu-menuItem-bg-primary: #A1A2A7; */
  /* --light-fltMenu-menuItem-bg-secondary: #A0A0A2; */
  --light-fltMenu-menuItem-bg-hover-primary: #F3F3F3;
  //menuItem-icon-colors:
  --light-fltMenu-menuItem-icon-primary: #5E5E5F;
  --light-fltMenu-menuItem-icon-hover-primary: #5E5E5F;

  //background-color:
  --light-fltMenu-bg-primary: #FFFFFF;
  //border-color:
  --light-fltMenu-border-primary: #D8D8D8;
  //box-shadow:
  --light-fltMenu-box-primary: #00000011;
  //chip-colors;
  --light-fltMenu-chip-text-primary: #2F2F31;
  //chip-background-color:
  --light-fltMenu-chip-bg-primary: #FFFFFF;
  /* --light-fltMenu-chip-bg-hover-primary:  */
/* --light-fltMenu-chip-border-primary:  */

  //icon-colors
  --light-icon-color-primary: #606260;
  --light-icon-color-secondary: #A5A7A5;
  --light-icon-color-danger: #EB5656;
  //fltTextInput-colors
  --light-flt-input-border: #E5E5E5; //add -text-
  --light-flt-input-hover: #BDBDBD;
  --light-flt-input-focus: #6d78d5;
  --light-flt-input-bg: #FFFFFF;
  --light-flt-input-text: #1C1C1C;
  --light-flt-input-error: #d32f2f;
  --light-flt-input-messageBox-bg-error :#F7C8C1;
  //fltSnackbar
  --light-fltSnackbar-text-primary: #2F2F31;
  --light-fltSnackBar-bg-primary: #FFFFFF;
   --light-fltSnackBar-box-primary: #091e424f;
  --light-fltSnackBar-box-secondary: #091e4240;
  --light-fltSnackBar-warning: #ed6c02;
  --light-fltSnackBar-danger: #d32f2f;
  --light-fltSnackBar-info: #0288d1;
  --light-fltSnackBar-success: #36B37E;
  //menu-colors;
  --light-menu-bg: #FFFFFF;
  --light-menu-border-primary: #E5E5E5;
  --light-menu-border-secondary: #EAEAEA;
  --light-menu-text-primary: #303230;
  --light-menu-text-secondary: #606260;
  --light-menu-icon-color-primary: #606260;
  --light-menu-chip-bg: #FFFFFF;
  --light-menu-chip-text-primary: #303230;
  --light-menu-chip-text-secondary: #606260;
  --light-menu-chip-border: #E5E5E5;
  //welcome-indicator-colors;
  --light-welcome-indicator-bg-primary: #E0E0E0;
  --light-welcome-indicator-bg-secondary: #9D9D9F;

    /* *** FltTable *** */
  //text-colors;
  --light-fltTable-text-primary: #1C1C1C; 
  --light-fltTable-text-secondary: #606260; 
  //background-colors:
  --light-fltTable-bg-primary: #FBFBFB; 
  --light-fltTable-bg-secondary: #FFFFFF; 
  //row hover
  --light-fltTable-hover-primary:#f9fafb;
  //border-colors:
  --light-fltTable-border-primary: #ECECEC; 
  --light-fltTable-border-secondary: #F7F7F7; 
  --light-fltTable-border-tertiary: #606260;
  //boxShadow-colors;
  --light-fltTable-box-primary:  #00000066;
  //select
  --light-fltTable-cell-select-bg-primary: #ADD8E6;
  --light-fltTable-cell-select-text-primary: #FFFFFF;
  //icon
  --light-fltTable-icon-status-active: #1CA912;
  --light-fltTable-icon-status-inactive: ##bec2c8;
  --light-fltTable-icon-arrow : #282A30;
  //scrollbar
  --light-scrollbar-thumb: #a0a7b0;
  --light-scrollbar-track: #e1e4e8;
  --light-scrollbar-thumb-border: #7a818a;

  /* Dark theme colors */
  --dark-text-primary: #FFFFFF;
  --dark-text-secondary: #9C9DA1;
  --dark-bg: #1E2022;
  --dark-border-primary: #2F3138;
  --dark-border-secondary: #26282F;

  //oldbutton-colors
  --dark-button-text-primary: #E4E5E9;
  --dark-button-text-secondary: #FFFFFF;
  --dark-button-bg-primary: #2B2D34;
  --dark-button-bg-secondary: #5E69D1;
  --dark-button-border-primary: #373940;
  --dark-button-border-secondary: #6A76E3;

    //Pages colors
  //text-colors:
  --dark-pages-text-primary: #FFFFFF;
  --dark-pages-text-secondary: #606260;
  //Background-colors:
  --dark-pages-bg-primary:#1A1C1D;// #FBFBFB;
  //Border-colors:
  --dark-pages-border-primary:#2F3138; 
  --dark-pages-border-secondary:#26282F;
  //drop shadow
  --dark-pages-dropShadow:#00000052;
  --dark-pages-avatar-border-primary:#bdb9b9;
  //layout:
  --dark-pages-layout-text-primary:#f3f3f3;
  --dark-pages-scroll-color:#556268;
  --dark-pages-layout-border:#303236;
  --dark-pages-layout-bg:#18191a;
   --dark-pages-layout-hover-primary:#263238;
   //secondary-sidebar
    --dark-pages-layout-secondarySidebar-bg:#5b5c5d33;
  --dark-pages-layout-secondarySidebar-bg-hover-primary:#313032;
/* *** FltButton *** */
  //Text-colors:
  --dark-fltButton-text-primary:#FEFEFF;
  --dark-fltButton-text-secondary: #E5E7EC;
  --dark-fltButton-text-tertiary: #6D78D5;
  --dark-fltButton-text-danger: #EB5656;
  //Background-colors:
  --dark-fltButton-bg-primary: #6D78D5;
  --dark-fltButton-bg-secondary: #2B2D34;
  --dark-fltButton-bg-danger: #EB5656;
  --dark-fltButton-bg-hover-primary: #6A76E3;
  --dark-fltButton-bg-hover-secondary: #212327;
  --dark-fltButton-bg-hover-danger: #DC484B;
  //Border-colors:
  --dark-fltButton-border-primary: #6671C9;
  --dark-fltButton-border-secondary: #414447;
  --dark-fltButton-border-danger: #DC484B;
  //Box-shadow-colors:
  --dark-fltButton-box-primary: #00000005;
  --dark-fltButton-box-hover-primary: #bcc3f2;
  --dark-fltButton-box-hover-secondary: #2b2d2f;
  --dark-fltButton-box-hover-danger: #f9b1b1; 

  /* *** FltInput *** */
  //Text-colors:
  --dark-fltInput-text-primary: #FFFFFF;
  --dark-fltInput-text-error:  #FF6D6A;
  //Background-colors:
  --dark-fltInput-bg-primary: #1E2022;
  //Border-color:
  --dark-fltInput-border-primary: #373940;
  --dark-fltInput-border-error: #FF6D6A;
  --dark-fltInput-border-hover-primary: #464B5A;
  --dark-fltInput-border-hover-error: #FF6D6A;
  --dark-fltInput-border-focus-primary: #5e69d1;
  --dark-fltInput-border-focus-error: #FF6D6A;
  --dark-flt-input-messageBox-bg-error :#F7C8C1;
  //box-shadow:
  --dark-fltInput-box-primary: #0000000F;

   /**** / FltCheckbox  **** */
--dark-fltCheckbox-checked-border-primary:#5E69D1;
--dark-fltCheckbox-checked-bg-primary:#5E69D1;
--dark-fltCheckbox-unchecked-border-primary:#5E69D1;
--dark-fltCheckbox-checked-box-primary:#5E69D1;
--dark-fltCheckbox-checked-box-secondary:#00000033;
--dark-fltCheckbox-unchecked-box-primary:#5E69D1;
--dark-fltCheckbox-unchecked-box-secondary:#00000033;
/**** / FltCheckbox  **** */
--dark-fltSwitch-bg-primary:#dddddd;
--dark-fltSwitch-box-primary:#cccccc;
/**** / FltRadio **** */
--dark-fltRadio-checked-bg-primary: #6d78d5;
--dark-fltRadio-checked-bg-hover:#6d78d5;
--dark-fltRadio-checked-outline-primary:#6d78d5;
--dark-fltRadio-checked-box-primary:#6d78d5;
--dark-fltRadio-unchecked-bg-primary:#394b5980;
--dark-fltRadio-unchecked-bg-hover:#30404d;
--dark-fltRadio-unchecked-outline-primary: #137cbd99;
--dark-fltRadio-unchecked-box-primary:#10161a1a;
--dark-fltRadio-unchecked-box-secondary:#10161a1a;
--dark-fltRadio-unchecked-bg-image-primary:#ffffff0d;
--dark-fltRadio-unchecked-bg-image-secondary:#ffffff00;
--dark-fltRadio-checked-bg-image-primary:#ffffff1a;
--dark-fltRadio-checked-bg-image-secondary:#ffffff00;
  /* *** FltModal *** */
  //text-colors;
  --dark-fltModal-text-primary: #FFFFFF;
  --dark-fltModal-text-secondary: #6A6B6F;
  //background-colors:
  --dark-fltModal-bg-primary: #282A2E;
  //border-colors:
  --dark-fltModal-border-primary: #41444D;
  //backdrop
  --dark-fltModal-backdrop-primary:#00000019
;
  //boxShadow-colors;
  --dark-fltModal-box-primary: #00000019;
  --dark-fltModal-box-secondary: #0000001f;

  /* *** FltMenu *** */
  //menuItem-text-colors:
  --dark-fltMenu-menuItem-text-primary: #A1A2A7;
  --dark-fltMenu-menuItem-text-secondary: #A0A0A2;
  --dark-fltMenu-menuItem-text-hover-primary: #FFFFFF;
  //menuItem-background-colors
 /* --dark-fltMenu-menuItem-bg-primary: #A1A2A7; */
  /* --dark-fltMenu-menuItem-bg-secondary: #A0A0A2; */
  --dark-fltMenu-menuItem-bg-hover-primary: #383846;
  //menuItem-icon-colors:
  --dark-fltMenu-menuItem-icon-primary: #A1A2A7;
  --dark-fltMenu-menuItem-icon-hover-primary: #FFFFFF;

  //background-color:
  --dark-fltMenu-bg-primary: #282a2e;
  //border-color:
  --dark-fltMenu-border-primary: #41444d;
  //box-shadow:
  --dark-fltMenu-box-primary: #0000001f;
  //chip-colors;
  --dark-fltMenu-chip-text-primary: #E4E5E9;
  //background-color:
  --dark-fltMenu-chip-bg-primary: #2B2D34;
  /* --dark-fltMenu-chip-bg-hover-primary:  */
/* --dark-fltMenu-chip-border-primary:  */

  --dark-icon-color-primary: #9C9DA1;
  --dark-icon-color-secondary: #A5A7A5;
  --dark-icon-color-danger: #EB5656;

  --dark-flt-input-border: #373940;
  --dark-flt-input-hover: #464B5A;
  --dark-flt-input-focus: #5e69d1;
  --dark-flt-input-bg: #1E2022;
  --dark-flt-input-text: #FFFFFF;
  --dark-flt-input-error: #d32f2f;

  --dark-fltSnackbar-text-primary: #2F2F31;
  --dark-fltSnackBar-bg-primary: #FFFFFF;
  --dark-fltSnackBar-box-primary: #091e424f;
  --dark-fltSnackBar-box-secondary: #091e4240;
  --dark-fltSnackBar-warning: #ed6c02;
  --dark-fltSnackBar-danger: #d32f2f;
  --dark-fltSnackBar-info: #0288d1;
  --dark-fltSnackBar-success: #36B37E;

  --dark-menu-bg: #282A2E;
  --dark-menu-border-primary: #41444D;
  --dark-menu-border-secondary: #3C3D47;
  --dark-menu-text-primary: #E5E7EC;
  --dark-menu-text-secondary: #A1A2A7;
  --dark-menu-icon-color-primary: #A1A2A7;
  --dark-menu-chip-bg: #353840;
  --dark-menu-chip-text-primary: #E5E7EC;
  --dark-menu-chip-text-secondary: #A1A2A7;
  --dark-menu-chip-border: #41444D;
  --dark-welcome-indicator-bg-primary: #23252A;
  --dark-welcome-indicator-bg-secondary: #595A5C;

      /* *** FltTable *** */
  //text-colors;
  --dark-fltTable-text-primary: #FFFFFF;
  --dark-fltTable-text-secondary: #9C9DA1;
  //background-colors:
  --dark-fltTable-bg-primary: #1E2022;
  --dark-fltTable-bg-secondary: #282A2E; 
  //row hover
  --dark-fltTable-hover-primary: #222222;
  //border-colors:
  --dark-fltTable-border-primary: #2F3138;
  --dark-fltTable-border-secondary: #26282F;
  --dark-fltTable-border-tertiary: #9C9DA1;
  //boxShadow-colors;
  --dark-fltTable-box-primary: #00000066;

  //select cell
  --dark-fltTable-cell-select-bg-primary: #ADD8E6;
  --dark-fltTable-cell-select-text-primary: #FFFFFF;
  //icon
  --dark-fltTable-icon-status-active: #00AA00;
  --dark-fltTable-icon-status-inactive: #bec2c8;
  --dark-fltTable-icon-arrow : #282A30;

//scrollbar
  --dark-scrollbar-thumb: #444444;
  --dark-scrollbar-track: #2e2e2e;
  --dark-scrollbar-thumb-border: #2e2e2e;

}


/* font styles */
:root {
  // size
  --font-size-xs: 0.75rem; //12px
  --font-size-sm: 0.8125rem; //14px
  --font-size-base: 1rem; //16px
  --font-size-lg: 1.125rem; //18px
  --font-size-xl: 1.25rem; //20px
  --font-size-2xl: 1.5rem; //24px
  --font-size-3xl: 1.875rem; //30px
  --font-size-4xl: 2.25rem; //36px
  --font-size-5xl: 3rem;  //48px

   // height
   --line-height-xs: 1rem;
  --line-height-sm: 1.25rem;
  --line-height-base: 1.5rem;
  --line-height-lg: 1.75rem;
  --line-height-xl: 1.75rem;
  --line-height-2xl: 2rem;
  --line-height-3xl: 2.25rem;
  --line-height-4xl: 2.5rem;
  --line-height-5xl: 1;

  --spacing-0x: 0px;
  --spacing-0-25x: 2px;
  --spacing-0-50x: 4px;
  --spacing-0-75x: 6px;
  --spacing-1x: 8px;
  --spacing-1-5x: 12px;
  --spacing-2x: 16px;
  --spacing-2-5x: 20px;
  --spacing-3x: 24px;
  --spacing-3-5x: 28px;
  --spacing-4x: 32px;
  --spacing-4-5x:36px;
  --spacing-5x: 40px;
  --spacing-6x: 48px;
  --spacing-8x: 64px;
  --spacing-10x: 80px;


//Official Colors
--brand-blue-90: #000C19;
--brand-blue-80: #002655;
--brand-blue-70: #004091;
--brand-blue-60: #024EAE;
--brand-blue-50: #306FBE;
--brand-blue-40: #5E90CE;
--brand-blue-30: #8CB1DE;
--brand-blue-20: #BAD2EE;
--brand-blue-10: #E6F1FF;
--green-90: #016027;
--green-80: #017C32;
--green-70: #029E41;
--green-60: #02AE46;
--green-50: #35BE6C;
--green-40: #55C984;
--green-30: #8BDAAA;
--green-20: #B1E6C6;
--green-10: #E6F7ED;
--grey-bg-light: #131416;
--grey-bg-dark: #0A0A0B;
--grey-100: #0A0A0B;
--grey-90: #131416;
--grey-80: #1C1E21;
--grey-70: #26282C;
--grey-65: #37393D;
--grey-60: #494C50;
--grey-50: #85898E;
--grey-40: #AFB2B6;
--grey-30: #CFD1D3;
--grey-20: #EAEBEB;
--grey-10: #F2F2F3;
--grey-0: #FCFCFC;

/* --dark-opacity-button-active-: #ffffff14;//
--light-opacity-button-active: #000c1914;//
--dark-opacity-button-hover: #ffffff0a;//
--light-opacity-button-hover: #000c190a;//
--dark-opacity-button-default: #ffffff00;//
--light-opacity-button-default: #000c1900;// */

--opacity-caution-colors-error-opacity12: #ca12121f;
--opacity-caution-colors-success-opacity12: #02ae471f;
--opacity-caution-colors-warning-opacity12: #d46e0f1f;


--light-opacity-grey-00: #0A0A0B00;
--light-opacity-grey-02: #0A0A0B05;
--dark-opacity-grey-02: #FFFFFF05;
--light-opacity-grey-04: #0A0A0B0A;
--dark-opacity-grey-04: #FFFFFF0A;
--dark-opacity-grey-00: #FFFFFF00;
--light-opacity-grey-08: #0A0A0B14;
--light-opacity-grey-16: #0A0A0B33;
--dark-opacity-grey-16: #FFFFFF33;
--dark-opacity-grey-08: #FFFFFF14;
--light-opacity-grey-20: #0A0A0B33;
--dark-opacity-grey-20: #FFFFFF33;
--light-opacity-grey-40: #0A0A0B66;
--dark-opacity-grey-40: #FFFFFF66;

--orange-90: #602F01;
--orange-80: #7C3C01;
--orange-70: #9E4D02;
--orange-60: #D46D0E;
--orange-50: #BE7735;
--orange-40: #C98D55;
--orange-30: #DAB18B;
--orange-20: #E6CAB1;
--orange-10: #F7EEE6;
--red-90: #600101;
--red-80: #7C0101;
--red-70: #9E0202;
--red-60: #C91111;
--red-50: #BE3535;
--red-40: #C95555;
--red-30: #DA8B8B;
--red-20: #E6B1B1;
--red-10: #F7E6E6;
--absolute-white: #FFFFFF;
--absolute-black: #000000;


  //Light Theme Colors
  --light-border-inverse: var(--absolute-white);
  --light-border-accent: var(--brand-blue-40);
  --light-border-error: var(--red-60);
  --light-border-primary: var(--grey-40);
  --light-border-secondary: var(--grey-30);
  --light-border-tertiary: var(--grey-20);

  --light-content-accent-icon: var(--brand-blue-60);
  --light-content-accent-text: var(--absolute-white);
  --light-content-error: var(--red-50);
  --light-content-primary: var(--grey-80);
  --light-content-secondary: var(--grey-70);
  --light-content-static: var(--grey-50);
  --light-content-success: var(--green-60);
  --light-content-tertiary: var(--grey-60);
  --light-content-warning: var(--orange-60);
  
  --light-content-button-primary-active: var(--absolute-white);
  --light-content-button-primary-default: var(--absolute-white);
  --light-content-button-primary-disabled: var(--grey-20);
  --light-content-button-primary-hover: var(--brand-blue-10);

  --light-content-button-secondary-active: var(--light-content-primary);
  --light-content-button-secondary-default: var(--light-content-secondary);
  --light-content-button-secondary-disabled: var(--light-content-static);
  --light-content-button-secondary-hover: var(--light-content-secondary);
  
  --light-content-button-tertiary-active: var(--grey-80);
  --light-content-button-tertiary-default: var(--grey-70);
  --light-content-button-tertiary-disabled: var(--grey-30);
  --light-content-button-tertiary-hover: var(--grey-60);

  --light-surface-switch-active: var(--brand-blue-60);
  --light-surface-switch-default: var(--grey-0);
  --light-surface-switch-hover: var(--grey-0);
  --light-surface-toggle-default: var(--absolute-white);

  --light-surface-background-error: var(--red-10);
  --light-surface-background-primary: var(--grey-20);
  --light-surface-background-secondary: var(--grey-0);
  --light-surface-background-success: var(--green-10);
  --light-surface-background-tertiary: var(--grey-10);
  --light-surface-background-warning: var(--orange-10);
  --light-surface-background-hover: var(--light-opacity-grey-02);


  --light-surface-button-primary-active: var(--brand-blue-70);
  --light-surface-button-primary-default: var(--brand-blue-60);
  --light-surface-button-primary-disabled: var(--grey-40);
  --light-surface-button-primary-hover: var(--brand-blue-50);

  --light-surface-button-secondary-active: var(--grey-10);
  --light-surface-button-secondary-default: var(--absolute-white);
  --light-surface-button-secondary-disabled: var(--grey-0);
  --light-surface-button-secondary-hover: var(--grey-10);

  --light-surface-button-tertiary-active: var(--light-opacity-grey-04);
  --light-surface-button-tertiary-default: var(--light-opacity-grey-00)
  --light-surface-button-tertiary-disabled: var(--absolute-white);
  --light-surface-button-tertiary-hover: var(--light-opacity-grey-04);

  /* --light-surface-toggle-active: var(--absolute-white);//
  --light-surface-toggle-default: var(--absolute-white);//
  --light-surface-toggle-inactive: '';//
  --light-surface-toggle-hover: var(--absolute-white);// */

  --light-box-shadow-small : 0px 1px 4px 0px rgba(212, 212, 212, 0.16);
  --light-box-shadow-medium : 0px 4px 8px 0px rgba(212, 212, 212, 0.34);
  --light-box-shadow-focused : 0px 0px 0px 2px rgba(2, 78, 174, 0.20);


  //Dark Theme Colors
  --dark-border-inverse: var(--grey-bg-light);
  --dark-border-accent: var(--brand-blue-60);
  --dark-border-error: var(--red-40);
  --dark-border-primary: var(--grey-60);
  --dark-border-secondary: var(--grey-65);
  --dark-border-tertiary: var(--grey-70);

  --dark-content-accent-icon: var(--brand-blue-50);
  --dark-content-accent-text: var(--absolute-white);
  --dark-content-error: var(--red-50);
  --dark-content-primary: var(--grey-0);
  --dark-content-secondary: var(--grey-30);
  --dark-content-static: var(--grey-50);
  --dark-content-success: var(--green-50);
  --dark-content-tertiary: var(--grey-40);
  --dark-content-warning: var(--orange-50);

  --dark-content-button-primary-active: var(--absolute-white);
  --dark-content-button-primary-default: var(--absolute-white);
  --dark-content-button-primary-disabled: var(--grey-70);
  --dark-content-button-primary-hover: var(--brand-blue-10);
  --dark-content-button-secondary-active: var(--dark-content-primary);
  --dark-content-button-secondary-default: var(--dark-content-secondary);
  --dark-content-button-secondary-disabled: var(--dark-content-static);
  --dark-content-button-secondary-hover: var(--dark-content-secondary);
  --dark-content-button-tertiary-active: var(--grey-0);
  --dark-content-button-tertiary-default: var(--grey-10);
  --dark-content-button-tertiary-disabled: var(--grey-70);
  --dark-content-button-tertiary-hover: var(--grey-20);

  --dark-surface-switch-active: var(--brand-blue-50);
  --dark-surface-switch-default: var(--grey-0);
  --dark-surface-switch-hover: var(--grey-0);
  //--dark-surface-toggle-default: var(--grey-bg-light);

  --dark-surface-background-error: var(--opacity-caution-colors-error-opacity12);
  --dark-surface-background-primary: var(--grey-100);
  --dark-surface-background-secondary: var(--grey-90);
  --dark-surface-background-success:  var(--opacity-caution-colors-success-opacity12);
  --dark-surface-background-tertiary: var(--grey-80);
  --dark-surface-background-warning: var(--opacity-caution-colors-warning-opacity12);
  --dark-surface-background-hover: var(--dark-opacity-grey-02);
  --dark-surface-button-primary-active: var(--brand-blue-70);
  --dark-surface-button-primary-default: var(--brand-blue-60);
  --dark-surface-button-primary-disabled: var(--grey-80);
  --dark-surface-button-primary-hover: var(--brand-blue-50);

  --dark-surface-button-secondary-active: var(--grey-80);
  --dark-surface-button-secondary-default: var(--grey-90);
  --dark-surface-button-secondary-disabled: var(--grey-80);
  --dark-surface-button-secondary-hover: var(--grey-80);

  --dark-surface-button-tertiary-active: var(--dark-opacity-grey-04);
  --dark-surface-button-tertiary-default:var(--dark-opacity-grey-00);
  --dark-surface-button-tertiary-disabled: var(--grey-bg-light);
  --dark-surface-button-tertiary-hover: var(--dark-opacity-grey-04);

  --dark-box-shadow-small : 0px 1px 4px 0px rgba(14, 14, 14, 0.16);
  --dark-box-shadow-medium : 0px 4px 8px 0px rgba(14, 14, 14, 0.34);
  --dark-box-shadow-focused : 0px 0px 0px 2px rgba(2, 78, 174, 0.40);

  /* --dark-surface-toggle-active: #1A1C1D;
  --dark-surface-toggle-default: #1A1C1D;
  --dark-surface-toggle-inactive: '';
  --dark-surface-toggle-hover: #1A1C1D; */


--light-surface-overlay-primary: var(--absolute-white);
--light-surface-overlay-secondary: var(--grey-10);


--dark-surface-overlay-primary: var(--grey-80);
--dark-surface-overlay-secondary: var(--grey-70);

}

`;

export default Variables;
