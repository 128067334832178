import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Email } from '@src/dbSyncManager/tables/email.entity';
import { Party } from '@src/dbSyncManager/tables/parties.entity';

interface EmailState {
  mapData: Map<number | null, any>;
  arrayData: any[];
}

const initialState: EmailState = {
  mapData: new Map(),
  arrayData: [],
};

const emailsSlice = createSlice({
  name: 'parties',
  initialState,
  reducers: {
    setEmails(state, action: PayloadAction<Email[]>) {
      state.arrayData = action.payload;
      state.mapData = new Map(action.payload.map((item) => [item.id, item]));
    },
  },
});

export const { setEmails } = emailsSlice.actions;
export default emailsSlice.reducer;
