import { AppState } from '@src/app/rootReducer';
import { Theme } from '@src/styles/themes/themeInterface';
import React, { FocusEvent, KeyboardEvent, RefObject } from 'react';
import { useSelector } from 'react-redux';
import styled, { css, CSSObject } from 'styled-components';
import { typography } from './typography';

// Define the union type for variant values based on the theme typography keys
export type TextVariant =
  | 'display-extra-small'
  | 'display-small'
  | 'display-medium'
  | 'display-large'
  | 'heading-small'
  | 'heading-medium'
  | 'heading-large'
  | 'body-small'
  | 'body-medium'
  | 'body-large'
  | 'title-small'
  | 'title-medium'
  | 'title-large'
  | 'label-ultra-small'
  | 'label-small'
  | 'label-medium'
  | 'label-large';

export type TextColorVariant =
  | 'content-primary'
  | 'content-secondary'
  | 'content-static'
  | 'content-success'
  | 'content-error'
  | 'content-tertiary'
  | 'content-accentText'
  | 'content-accentIcon';

// Define the props for the FltText component
interface FltTextProps {
  variant: TextVariant;
  styles?: React.CSSProperties;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
  hoverColor?: string;
  textColor?: TextColorVariant;
  theme?: Theme;
  isEllipsis?: boolean;
  onBlur?: (event: FocusEvent<HTMLDivElement>) => void; // Event handler for blur
  onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void; // Event handler for keydown
  divRef?: RefObject<HTMLDivElement>;
  isEditable?: boolean;
}

// Generate CSS styles based on the variant
const variantStyles = (variant: TextVariant) => css`
  font-family: ${typography[variant].fontFamily};
  font-size: ${typography[variant].fontSize};
  font-weight: ${typography[variant].fontWeight};
  line-height: ${typography[variant].lineHeight};
  letter-spacing: ${typography[variant].letterSpacing};
`;

// Helper function to get text color from theme based on the textColor prop
const getTextColor = (textColor: TextColorVariant, theme: Theme) => {
  // Split the textColor by '-' to get the keys (e.g., ['content', 'primary'])
  const colorKeys = textColor.split('-');

  // If the textColor contains 'accent-icon', handle it differently
  if (textColor === 'content-accentText') {
    return theme.content.accentText || theme.content.primary;
  }

  // Extract the color variant (e.g., 'primary', 'secondary', etc.)
  const colorKey = colorKeys[1]; // This will take 'primary', 'secondary', etc.

  // Access the color in the theme object dynamically
  return theme.content[colorKey as keyof typeof theme.content] || theme.content.primary;
};

// Styled component for text with variant and optional inline styles
const StyledText = styled.span<FltTextProps>`
  /* ${({ variant }) => variantStyles(variant)} */
  ${({ variant, hoverColor, textColor, theme }) => css`
    color: ${textColor
      ? getTextColor(textColor, theme!)
      : theme.content.primary}; // Set color based on textColor prop
    ${variantStyles(variant)}
    &:hover {
      color: ${hoverColor || ''}; // Apply hover color if provided, otherwise use default
    }
  `}

  ${({ isEllipsis }) =>
    isEllipsis &&
    css`
      white-space: nowrap; // Prevent text from wrapping
      overflow: hidden; // Hide overflow
      text-overflow: ellipsis; // Show ellipsis when text is truncated
    `}

  ${({ styles }) => styles && css(styles as CSSObject)}
`;

// FltText component that renders styled text based on the variant and optional styles
const FltText: React.FC<FltTextProps> = React.memo(
  ({
    variant,
    styles,
    children,
    onClick,
    hoverColor,
    textColor,
    isEllipsis = false,divRef,
    onBlur,
    onKeyDown,
    isEditable = false,
  }) => {
    const theme = useSelector((color: AppState) => color.theme.theme);
    return (
      <StyledText
        variant={variant}
        textColor={textColor}
        styles={styles}
        onClick={onClick}
        hoverColor={hoverColor}
        theme={theme}
        isEllipsis={isEllipsis}
        contentEditable={isEditable}
        ref={divRef}
        onBlur={onBlur} // Trigger when the user clicks outside
        onKeyDown={onKeyDown} // Trigger when the Enter key is pressed
        suppressContentEditableWarning={isEditable}
      >
        {children}
      </StyledText>
    );
  }
);

export default FltText;
