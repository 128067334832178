import { Theme } from '@src/styles/themes/themeInterface';
import styled from 'styled-components';

export const HeaderWrapper = styled.div<{ theme: Theme }>`
  width: 100%;
  /* min-height: 60px; */
  padding: var(--spacing-0-75x, 6px) var(--spacing-2x, 16px);

  transition: opacity 0.05s ease-in-out 0s;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.surface.background.secondary};
  border-bottom: ${({ theme }) => `1px solid ${theme.border.tertiary}`};
  height: 40px;
  .left-container {
    /* gap: 4px; */
    height: 100%;
    min-width: 0px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.pages.layout.header.text.secondary};
    flex: 1;
    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      /* padding: 0px 6px; */
    }
    .side-bar-toggle-btn-container {
      display: flex;
      align-items: center;
      margin-right: 8px;
    }
    .divider {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -0.36px;
      padding-left: 4px;
      padding-right: 4px;
      margin-top: 2px;
    }
    .previous-page {
      cursor: pointer;
      text-align: left;
      overflow: hidden;
      font-style: normal;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: ${({ theme }) => theme.pages.text.primary};
      line-height: normal;
      font-size: 13px;
      font-weight: 500;
    }
    .icon-div {
      display: flex;
    }

    .current-page {
      text-align: left;
      overflow: hidden;
      cursor: pointer;
      font-style: normal;
      color: ${({ theme }) => theme.pages.text.secondary};
      line-height: normal;
      font-size: 13px;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .right-container {
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${({ theme }) => theme.pages.layout.header.text.secondary};
    .search-anything-btn {
      display: flex;
      align-items: center;
      height: 28px;
      border-radius: 6px;
      gap: 4px;
      padding: 0px 10px;
      cursor: pointer;
      background-color: transparent;
      margin-right: 12px;
      border: ${({ theme }) => `1px solid ${theme.border.tertiary}`};
      background-color: ${({ theme }) => theme.fltInput.background.primary};
    }
    .search-anything-btn:hover {
      box-shadow: ${({ theme }) => theme.boxshadow.small};
      border-color: ${({ theme }) => theme.border.tertiary};
    }
  }

  @media (max-width: 540px) {
    padding: 0 20px;
  }
`;
