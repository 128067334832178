import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '@src/dbSyncManager/tables/user.entity';

interface UserState {
  mapData: Map<number | null, any>;
  arrayData: any[];
}

const initialState: UserState = {
  mapData: new Map(),
  arrayData: [],
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers(state, action: PayloadAction<User[]>) {
      state.arrayData = action.payload;
      state.mapData = new Map(action.payload.map((item) => [item.id, item]));
    },
  },
});

export const { setUsers } = usersSlice.actions;
export default usersSlice.reducer;
